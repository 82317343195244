import  { useEffect, useState} from "react";
import PrivateTemplate from "views/PrivateTemplate";
import Materials from "components/CreateProject/Materials";
import ProjectCalc from "components/CreateProject/ProjectCalc";
import Details from "components/Details";
import "./index.scss";
import {useDispatch, useSelector} from "react-redux";
import { projectSelectors } from "redux/project";
import {empty} from "../../helpers/helper";
import {modalsOperations, modalsSelectors} from "redux/modals";
import {panelOperations} from "redux/panel";
import {useUpdateTranslations} from "../../hooks/useUpdateTranslations";

// import {TableRefContextProvider} from "../../contexts";

const Projects = () => {
  const construction = useSelector(projectSelectors.getConstruction);
  const dispatch = useDispatch()
  const modalState = useSelector(modalsSelectors.getModalShowState)
  const [materials, setMaterials] = useState([]);
  useUpdateTranslations();
  useEffect(()=>{
    if (modalState){
      dispatch(
        modalsOperations.switchStateForModal({
          show: false,
          type: null,
        })
      );
    }
    if(construction && !empty(construction.materials)) {
      setMaterials(construction.materials)
    }
    dispatch(
      panelOperations.switchStateForPanel({
        show: 'none'
      })
    )
  }, [])

  if(construction && empty(construction.details) && empty(construction.materials) && empty(construction.edges)) {
    window.location = '/'
  }

  return (
		// <TableRefContextProvider>
			<PrivateTemplate>
				<div className="main">
					<div className="layout-project">
						<ProjectCalc/>
						<Materials materials={materials} setMaterials={setMaterials}/>
						<Details />
					</div>
				</div>
			</PrivateTemplate>
		// </TableRefContextProvider>
  );
};

export default Projects;
