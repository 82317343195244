import Base from "./Base";
import ConnectDetails from "./ConnectDetails";
import {Box3, Group, Vector3} from "three";
import { CSG } from 'three-csg-ts';
import {empty} from "../../../helpers/helper";
import {log} from "three/examples/jsm/nodes/math/MathNode";

export default class ResizeProduct extends Base {
	connect = new ConnectDetails();

	// resizeProduct(productId, newSize) {
	// 	const scene = this.getScene();
	// 	const details = this.getProductDetails(productId);
	// 	const oldProductSize = this.getProductSize(productId);
	//
	// 	let scaleFactor = new Vector3();
	// 	scaleFactor.z = newSize.z / oldProductSize.z;
	// 	scaleFactor.x = newSize.x / oldProductSize.x;
	// 	scaleFactor.y = newSize.y / oldProductSize.y;
	//
	// 	const scaleAxis = (axis, updateDetail = false, isFirstStep = false) => {
	// 		return new Promise(resolve => {
	//
	// 			const scaleDetail = (i) => {
	// 				const detail = details[i];
	// 				if(empty(detail)) {
	// 					resolve();
	// 				} else {
	// 					let meshGroup = detail.mesh;
	//
	// 					const box = new Box3().setFromObject(meshGroup);
	// 					const size = box.getSize(new Vector3());
	// 					console.log(box);
	// 					const {l, h, w} = detail;
	// 					const dimension = {};
	// 					const axisDefault = {}
	// 					for(const [_axis, value] of Object.entries(size)) {
	// 						if(Number(value.toFixed(2)) === Number(l.toFixed(2)) ) {
	// 							dimension[_axis] = 'l'
	// 							axisDefault[_axis] = 'x';
	// 						} else if(Number(value.toFixed(2)) === Number(h.toFixed(2))) {
	// 							dimension[_axis] = 'h'
	// 							axisDefault[_axis] = 'y';
	// 						} else if(Number(value.toFixed(2)) === Number(w.toFixed(2))) {
	// 							dimension[_axis] = 'w'
	// 							axisDefault[_axis] = 'z';
	// 						}
	// 					}
	// 					if(!meshGroup.userData?.scale) {
	// 						meshGroup.userData.scale = {};
	// 					}
	// 					meshGroup.userData.scale[axis] = dimension[axis];
	// 					meshGroup.userData.scale.axis = [axis];
	// 					if(dimension[axis] === 'w') {
	// 						meshGroup.userData.scale.scaled = false;
	//
	// 						// meshGroup.userData.revertScale = axisDefault[axis];
	// 						// meshGroup.userData.scaleBox = box;
	// 						// meshGroup.userData.scalePosition = meshGroup.position;
	// 						// scene.render();
	// 						scaleDetail(i + 1)
	// 					} else {
	// 						const newDetailSize = detail[dimension[axis]] *= scaleFactor[axis];
	// 						if(newDetailSize.toFixed(0) !== newSize[axis].toFixed(0) && isFirstStep) {
	// 							meshGroup.scale[axisDefault[axis]] = scaleFactor[axis] + scaleFactor[axis] * 0.05;
	// 						} else {
	// 							meshGroup.scale[axisDefault[axis]] = scaleFactor[axis];
	// 						}
	// 						meshGroup.userData.scale.scaled = true;
	// 						const box_ = new Box3().setFromObject(meshGroup);
	// 						const size_ = box_.getSize(new Vector3());
	// 						// console.log(size[axis], size_[axis])
	// 						// console.log({[dimension[axis]]: size_[axis]})
	// 						// detail.updateSizeParams({[dimension[axis]]: Number(size_[axis].toFixed(2))})
	// 						// console.log(detail[dimension[axis]])
	// 						if(updateDetail) {
	// 							// console.log({[dimension[axis]]: Number(size_[axis].toFixed(2))})
	// 							detail.updateSize({[dimension[axis]]: Number(size_[axis].toFixed(2))})
	// 								.then(() => {
	// 									scaleDetail(i + 1)
	// 								})
	// 						} else {
	// 							detail.updateSizeParams({[dimension[axis]]: Number(size_[axis].toFixed(2))});
	// 							scaleDetail(i + 1)
	// 						}
	//
	// 					}
	// 				}
	// 			}
	//
	// 			scaleDetail(0)
	// 		})
	// 	}
	//
	// 	const axises = []
	//
	// 	for (const [axis, value] of Object.entries(scaleFactor)) {
	// 		if (Number(value.toFixed(4)) !== 1) {
	// 			axises.push(axis);
	// 		}
	// 	}
	//
	// 	const scale = () => {
	// 		return new Promise(resolve => {
	//
	// 			const scaleA = () => {
	// 				const axis = axises.shift();
	// 				if(!empty(axis)) {
	// 					scaleAxis(axis, false, true)
	// 						.then(() => this.connect.restoreConnectedDetails())
	// 						.then(() => {
	// 							const realSize = this.getProductSize(productId);
	// 							if(Number(realSize[axis].toFixed(2)) !== Number(newSize[axis].toFixed(2))) {
	// 								scaleFactor[axis] = 1;
	// 								return scaleAxis(axis)
	// 									.then(() => {
	// 										scaleFactor[axis] = (newSize[axis] + (newSize[axis] - realSize[axis]) ) / oldProductSize[axis];
	// 										return scaleAxis(axis, true, true)
	// 									})
	// 									.then(() => this.connect.restoreConnectedDetails())
	// 							} else {
	// 								return Promise.all(details.map(detail => detail.updateProjectDetail()))
	// 							}
	//
	// 						})
	// 						// .then(() => {
	// 						// 	details.forEach(detail => {
	// 						// 		detail.renderType = 2
	// 						// 	});
	// 						// 	setTimeout(() => {
	// 						// 		scene.render(true);
	// 						// 		return Promise.resolve();
	// 						// 	}, 10)
	// 						// })
	// 						// .then(() => Promise.all(details.map(detail => detail.updateSize({[dimension[axis]]: Number((size[axis] * scaleFactor[axis]).toFixed(2))}))))
	// 						.then(() => {
	// 							details.forEach((detail,i) => {
	// 								// scene.sceneModel.scene.add(detail.createBoxMesh());
	// 								// scene.render();
	// 								if(!detail.mesh.userData.scale.scaled) {
	// 									details.forEach(detail1 => {
	// 										if(detail1.detailCId !== detail.detailCId) {
	// 											if(detail1.mesh.userData.scale.scaled) {
	// 												// this.cropDetails(detail, detail1)
	// 											}
	// 										}
	// 									})
	// 								}
	// 							})
	// 							details.forEach((detail,i) => {
	// 								detail.mesh.userData.scale = null;
	// 							})
	// 							scene.render(true);
	// 							scaleA()
	// 						})
	// 				} else {
	// 					resolve()
	// 				}
	// 			}
	// 			// details.forEach(detail => {
	// 			// 	scene.addObject(detail.createBoxMesh());
	// 			//
	// 			//
	// 			// })
	//
	// 			scaleA()
	// 		})
	//
	// 	}
	//
	// 	return scale();
	// }


	detectDetailsOnAxis(productId, mainDetail, axis, productBox) {
		const details = this.getProductDetails(productId);
		const center = new Vector3();
		const detailBox = new Box3().setFromObject(mainDetail.mesh);
		detailBox.getCenter(center);
		const revert = {x: 'y', y: 'x', z: 'y'}
		const dept = {x: 'z', y: 'z', z: 'x'}
		let detailsW = 0;

		const _details = details.filter(detail => {
			const box = new Box3().setFromObject(detail.mesh);

			return center[revert[axis]] <= box.max[revert[axis]]
			&& center[revert[axis]] >= box.min[revert[axis]]
			&& center[dept[axis]] <= box.max[dept[axis]]
			&& center[dept[axis]] >= box.min[dept[axis]]
		})

		const detailsBox = new Box3()

		_details.forEach(detail => {
			detailsBox.expandByObject(detail.mesh)
			if(detail.dimension[axis] === 'w') {
				detailsW += detail.w
			}
		})
		const delta = productBox.getSize(new Vector3())[axis] - detailsBox.getSize(new Vector3())[axis];
		return detailsW + delta;
	}

	/**
	 * Adjusts the sizes of two connected details on a specified axis and returns the size differences.
	 *
	 * @param {Array<Object>} detail - An array of detail objects to process. Each detail should include properties such as `detailCId` and `mesh`.
	 * @param {string} axis - The axis along which the size adjustment is determined. Typically 'x', 'y', or 'z'.
	 * @return {{delta: number, size: number}} A promise that resolves to an object where the keys are `detailCId` values and the values are the calculated size differences on the specified axis.
	 */
	fixDetailsTwoConnectionsSize(detail, axis) {
		// return new Promise((resolve, reject) => {
			// details.forEach(detail => {
				const isConnectedTo = this.connect.getTargetConnections(detail.detailCId);
				if(isConnectedTo) {
					switch (isConnectedTo.length) {
						case 2:
							const box1 = new Box3().setFromObject(detail.mesh);
							const box2 = new Box3();
							isConnectedTo.forEach(connection => {
									box2.expandByObject(this.getDetailById(connection.mainDetail).mesh);
							})
							return {
								delta: box1.getSize(new Vector3())[axis] - box2.getSize(new Vector3())[axis],
								size: box2.getSize(new Vector3())[axis]
							};
						default:
							return {
								delta: 0,
								size: 0
							}
					}
				}
			// })
			// return delta;

	}

	resizeProduct(productId, newSize) {
			const scene = this.getScene();
			const details = this.getProductDetails(productId);
			const {box, size} = this.getProductSize(productId);
			const scaledAxis = [];
			let twoConnectionDelta = {};

			for (const [axis, val] of Object.entries(newSize)) {
				if(Number(val.toFixed(0)) !== Number(size[axis].toFixed(0))) {
					scaledAxis.push(axis)
				}
			}

			details.forEach(detail => detail.detectDimensionAxis())

			const scale = axis => {
				const deltas = {};
				details.forEach(detail => {
					deltas[detail.detailCId] = this.fixDetailsTwoConnectionsSize(detail, axis);
					if(detail.dimension[axis] !== 'w') {
						let notScaled = this.detectDetailsOnAxis(productId, detail, axis, box)
						detail.mesh.scale[detail.axisDefault[axis]] = (newSize[axis] - notScaled) / (size[axis] - notScaled);
					}
				})
				return Promise.resolve(deltas);
			}

			const scaleAxis = i => {
				return new Promise(resolve => {
					if(!empty(scaledAxis[i])) {
						scale(scaledAxis[i])
							.then(deltas => {
								return this.connect.restoreConnectedDetails()
									.then(() => {
										const promises = [];
										details.forEach(detail => {
											const newDelta = this.fixDetailsTwoConnectionsSize(detail, scaledAxis[i]);
											let _size = new Box3().setFromObject(detail.mesh).getSize(new Vector3());
											if(newDelta.delta !== deltas[detail.detailCId].delta) {
												detail.mesh.scale[detail.axisDefault[scaledAxis[i]]] = detail.mesh.scale[detail.axisDefault[scaledAxis[i]]] * (newDelta.size + deltas[detail.detailCId].delta) / _size[scaledAxis[i]];
												_size = new Box3().setFromObject(detail.mesh).getSize(new Vector3());
											}
											promises.push(detail.updateSize({[detail.dimension[scaledAxis[i]]]: Number(_size[scaledAxis[i]].toFixed(2))}))
										})
										return Promise.all(promises)
									})
							})
							.then(() => this.connect.restoreConnectedDetails())
							.then(() => {
								scene.render(true)
								// scene.render()
								return Promise.resolve()
							})
							.then(() => scaleAxis(i + 1))
					} else {
						resolve()
					}
				})

			}

			if(!empty(scaledAxis)) {
				return scaleAxis(0)
			} else {
				return Promise.resolve()
			}

	}
}