import React from "react";
import {useSelector} from "react-redux";
import { constructor3dSelectors } from "../../../../redux/constructor";


const Connection = ({connection, editConnection, deleteConnection}) => {
	const mainDetail = useSelector(constructor3dSelectors.getDetailById(connection.mainDetail))
	const targetDetail = useSelector(constructor3dSelectors.getDetailById(connection.targetDetail))

	const highlightConnection = (show) => {
		const type1 = connection.mainObjectId ? 'processing' : 'side';
		const type2 = connection.targetObjectId ? 'processing' : 'side';
		const pid1 = connection.mainObjectId ? connection.mainObjectId : connection.mainObject;
		const pid2 = connection.targetObjectId ? connection.targetObjectId : connection.targetObject;
		mainDetail.highlightChild(pid1, type1, show);
		targetDetail.highlightChild(pid2, type2, show);
	}

	return(
		<div className="block connections-item" onMouseEnter={e => highlightConnection(true)} onMouseLeave={e => highlightConnection(false)}>
			<div className="block__header connections-item__header">
				<span className="block__header-title connections-item__header-title">{`${targetDetail.name}->${connection.targetObject} to ${mainDetail.name}->${connection.mainObject}`}</span>
				<a className="block__header-control add-button" onClick={e => editConnection(connection.connectionId)}> &#9998;</a>
				<a className="block__header-control remove-button" onClick={e => deleteConnection(connection.connectionId)}> &times;</a>
			</div>
		</div>
	)

}

export default Connection;