import React  from "react";
import {useSelector, useDispatch} from "react-redux";
import {constructor3dSelectors, constructor3dOperations} from "../../../../redux/constructor";

import ControlButton from "../../components/ControlButton";
import './style.scss';
import {userSelectors} from "../../../../redux/user";
import {empty} from "../../../../helpers/helper";

export default function TopPanel() {
	const dispatch = useDispatch();
	const permissions = useSelector(userSelectors.permissions);
	const leftPanelShowed = useSelector(constructor3dSelectors.leftPanelOpened)
	const rightPanelOpened = useSelector(constructor3dSelectors.rightPanelOpened)
  const elements = useSelector(constructor3dSelectors.getTopPanelItems);

	const getClassName = () => {
		return `top-panel ${!leftPanelShowed ? 'left-hidden' : ''} ${!rightPanelOpened ? 'right-hidden' : ''}`
	}

	const checkPermissions = (_permissions = []) => {
		if(empty(_permissions)) return true;
		for(const p of _permissions) {
			if(permissions.includes(p)) return true;
		}
		return false;
	}

	const getItems = () => {
		return (
			<>
				{elements.map((el, i) => {
					if(!checkPermissions(el.permissions)) return null;
					return <ControlButton button={el} key={i} />
				})}
			</>
		)
	}

	return (
		<div className={getClassName()}>
			{getItems()}
		</div>
	)
}