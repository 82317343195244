import React, {useEffect, useState} from 'react';
import Base from "../../../model/components/Base";
import Tooltip from "../UI/Tooltip";
import Languages from "../../../../translation/Languages";

import './style.scss';
import {modalsOperations} from "./../../../../redux/modals";
import {useDispatch} from "react-redux";

export default function Materials(props) {
	const BaseClass = new Base();
	const {productId} = props;
	const [isOpen, setIsOpen] = useState(false);
	const [materials, setMaterials] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		setMaterials(BaseClass.getProductMaterials(productId))
	}, []);

	const changeMaterial = (index) => {
		const _details = BaseClass.getProjectDetails(BaseClass.getDetailsByMaterial(productId, index).map(el => el.detailId))
		dispatch(modalsOperations.switchStateForModal({
			show: true,
			type: 'REPLACE_MATERIAL_PROJECT',
			isEdit: _details,
			isReplacedMaterialForProject: false,
		}))
	}

	return (
		<div className="block connections block__materials">
			<div className="block__header connections__header icon-before-connections">
				<span className={'block__header-title connections__header-title'}>Матеріали {materials.length}</span>
				<a className={`block__header-control icon-button-arrow-ver ${isOpen ? 'hide' : 'show'}`}
					onClick={e => setIsOpen(!isOpen)}>{isOpen ? '-' : '+'}</a>
			</div>
			<div className={`block__body ${isOpen ? '' : 'hidden'}`}>
				{materials.map((el) => {
					const _name = Languages.getAvaliableTranslation(el.languages);
					return (
						<div className={'block'} key={el.index}>
							<div className={'block__header'}>
								<a className={'block__header-title c-pointer connections-item__header-title'}
									onMouseEnter={e => BaseClass.changeDetailsColorByMaterial(productId, el.index, true)}
									onMouseLeave={e => BaseClass.changeDetailsColorByMaterial(productId, el.index, false)}
								>{el.type} {el.article}</a>
								<Tooltip className={'icon-button-info'} showType={'hover'}>
									<p>{_name}</p>
								</Tooltip>
								<a className="block__header-control add-button" onClick={e => changeMaterial(el.index)}> &#9998;</a>
							</div>

						</div>
					)
				}

				)}
				</div>
		</div>
	)
}