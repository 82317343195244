import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {constructor3dOperations, constructor3dSelectors} from "./../../../../redux/constructor";
import Base from "../../../model/components/Base";
import ResizeProduct from "../../../model/components/ResizeProduct";
import {userSelectors} from "../../../../redux/user"
import Panel from "../../layuot/panel";
import {empty} from "../../../../helpers/helper";
import Helper from "../../../model/3d/Helper";
import UsedDetail from "../UsedDetail";
import Connections from "../Connections";
import {Modal, ModalFooter, ModalHeader} from "../../../../components/UI/Modal";
import {v4 as uuidv4} from "uuid";
import './style.scss'
import Tooltip from "../UI/Tooltip";
import Languages from "../../../../translation/Languages";
import CloseBtn from "../../../../components/UI/Buttons/CloseBtn";
import DefaultBtn from "../../../../components/UI/Buttons/DefaultBtn";
import Materials from "../Materials";


const UsedDetails = ({productId, title}) => {
	const permissions = useSelector(userSelectors.permissions);
	const usedDetails = useSelector(constructor3dSelectors.getCDetails);
	const details = usedDetails.filter(el => el.productId === productId);
	const selectedObjects = useSelector(constructor3dSelectors.getSelectedObjects)
	const BaseClass = new Base();
	const ResizeProductClass = new ResizeProduct();
	const dispatch = useDispatch();
	// const selectedDetails = useSelector(constructor3dSelectors.getSelectedDetails)
	const [optionsVisible, setOptionsVisible] = useState(false)
	// const [selectedDetails, setSelectedDetails] = useState([])
	const [detailsGroups, setDetailsGroup] = useState([])
	const [sizeModal, setSizeModal] = useState(false);
	const [minMaxSizeModal, setMinMaxSizeModal] = useState(false);
	const [minMaxSize, setMinMaxSize] = useState({
		min: {},
		max: {}
	});
	const [errors, setErrors] = useState({})
	const [size, setSize] = useState(null);
	const [productOptionsModal, setProductOptionsModal] = useState(false);

	const getDetailsGroups = () => {
		const tmp_obj = {};
		details.forEach(detail => {
			if(!empty(detail.group?.group_id)) {
				if(empty(tmp_obj[detail.group.group_id])) {
					tmp_obj[detail.group.group_id] = {
						name: detail.group.name,
						group_id: detail.group.group_id,
						details: []
					};
				}
				tmp_obj[detail.group.group_id].details.push(detail);
			} else {
				if(empty(tmp_obj?.noGroup)) {
					tmp_obj.noGroup = []
				}
				tmp_obj.noGroup.push(detail)
			}
		})
		return Object.values(tmp_obj)
	}

	useEffect(() => {
		// setSelectedDetails(usedDetails.filter(detail => detail.selected))
		setDetailsGroup(getDetailsGroups())
	}, [usedDetails])

	useEffect(() => {

	}, [detailsGroups]);

	useEffect(() => {
		const minMax = BaseClass.getProductMinMax(productId)
		if(!empty(minMax)) {
			setMinMaxSize(minMax)
		}
		// details.forEach(detail => {
		// 	detail.updateProjectDetailParams()
		// })
	}, [])

	if(empty(details)) return null;

	const lockProduct = () => {
		BaseClass.lockProduct(productId);
	}

	const toggleOptions = () => {
		setOptionsVisible(!optionsVisible)
	}

	const removeFromGroup = detail => {
		detail.removeFromGroup()
	}

	const mergeDetails = (selectedDetails) => {
		const name = prompt('Назва Об’єкту');
		if(!empty(name)) {
			const group = {
				group_id: uuidv4(),
				name
			}
			selectedDetails.forEach(detail => detail.addToGroup(group))
		}
	}

	const changeProductSize = () => {
		const { size} = BaseClass.getProductSize(productId);
		setSize({
			x: Number(size.x.toFixed(2)),
			y: Number(size.y.toFixed(2)),
			z: Number(size.z.toFixed(2))});
		setProductOptionsModal(false)
		setSizeModal(true)
	}

	const getSizesAxis = () => {
		return {
			x: 'width',
			y: 'height',
			z: 'depth'
		}
	}

	const saveProductSize = () => {
		let valid = true;
		const minMax = BaseClass.getProductMinMax(productId);
		const currentAxis = getSizesAxis();
		['x', 'y', 'z'].forEach(axis => {
			if(size[axis] < minMax.min[currentAxis[axis]]) {
				valid = false;
				setErrors({...errors, [currentAxis[axis]]: 'incorrect_min_size_error'})
				// setSize({...size, [axis]: minMax.min[currentAxis[axis]]})
			} else if(size[axis] > minMax.max[currentAxis[axis]]) {
				valid = false;
				setErrors({...errors, [currentAxis[axis]]: 'incorrect_max_size_error'})
				// setSize({...size, [axis]: minMax.max[currentAxis[axis]]})
			}
		})
		if(valid) {
			ResizeProductClass.resizeProduct(productId, size)
				.then(() => setSizeModal(false))
		}
		// if(val >= minMaxSize.min[axis] && value <= minMaxSize.max[axis]) {
		// 	setSize({...size, [axis]: val})
		// } else {
		// 	setErrors({...errors, 'minMax': {[axis]: 'incorrectSize'}})
		// }
		// ResizeProductClass.resizeProduct(productId, size);
	}

	const setProductMinMax = () => { //TODO: Should be implemented by product rotation
		const value = BaseClass.getProductMinMax(productId);
		if(value) {
			setMinMaxSize({
				min: {
					x: value.min.width,
					y: value.min.height,
					z: value.min.depth
				},
				max: {
					x: value.max.width,
					y: value.max.height,
					z: value.max.depth
				}
			})
		} else {
			const { size} = BaseClass.getProductSize(productId);
			setMinMaxSize({
				min: {
					x: Number(size.x.toFixed(2)),
					y: Number(size.y.toFixed(2)),
					z: Number(size.z.toFixed(2))
				},
				max: {
					x: Number(size.x.toFixed(2)),
					y: Number(size.y.toFixed(2)),
					z: Number(size.z.toFixed(2))
				}
			})
		}
		setProductOptionsModal(false)
		setMinMaxSizeModal(true)
	}

	const saveProductMinMax = () => {
		const data = { //TODO: Should be implemented by product rotation
			min: {
				height: minMaxSize.min?.y,
				width: minMaxSize.min?.x,
				depth: minMaxSize.min?.z
			},
			max: {
				height: minMaxSize.max?.y,
				width: minMaxSize.max?.x,
				depth: minMaxSize.max?.z
			}

		}
		BaseClass.updateProductMinMax(productId, data)
			.then(() => setMinMaxSizeModal(false))
	}

	const handlerChangeSize = (e, axis) => {
		const {value} = e.target;
		const correctAxis = getSizesAxis();
		const val = Number(value);
		setSize({...size, [axis]: val});
		setErrors({...errors, [correctAxis[axis]]: null})
	}

	const getError = () => {

	}

	const getEditSizeModal = () => {
		const correctAxis = getSizesAxis();
		const sizes = BaseClass.getProductMinMax(productId);
		if(empty(size) || !sizeModal) return null;
		return (
      <Modal isOpen={sizeModal}>
	      <div className="modal__wrapper constructor__modal-middle">
	      <ModalHeader className={'d-flex justify-content-between align-items-center'}>
		      <h3 className="addDetail__header-title">{Languages.getTranslation("changeProductSizeModalTitle", true)}</h3>
		      <CloseBtn handler={() => setSizeModal(false)}/>
	      </ModalHeader>
		      <div className={'modal__body constructor__modal-body d-flex flex-column'}>
            <div className="modal__input-group constructor__input-group mb-2">
              <label className={'constructor__input-label'} htmlFor="size-x">{Languages.getTranslation("optionsWidth", true)} {sizes ? ' (' + sizes.min.width +'-'+sizes.max.width + ')' : null}:</label>
              <input
	              className={'constructor__input'}
                type="number"
                id="size-x"
                value={size.x}
                onChange={(e) => handlerChangeSize(e, 'x')}
              />
	            {!empty(errors[correctAxis.x]) ? <span className="constructor__input-error">{Languages.getTranslation(errors[correctAxis.x], true)}</span> : null}
            </div>
            <div className="modal__input-group constructor__input-group mb-2">
              <label  className={'constructor__input-label'} htmlFor="size-y">{Languages.getTranslation("optionsHeight", true)}{sizes ? ' (' + sizes.min.height +'-'+sizes.max.height + ')' : null}:</label>
              <input
                type="number"
                id="size-y"
                value={size.y}
	              className={'constructor__input'}
                onChange={(e) => handlerChangeSize(e, 'y')}
              />
	            {!empty(errors[correctAxis.y]) ? <span className="constructor__input-error">{Languages.getTranslation(errors[correctAxis.y], true)}</span> : null}
            </div>
            <div className="modal__input-group constructor__input-group  mb-2">
              <label className={'constructor__input-label'} htmlFor="size-z">{Languages.getTranslation("optionsDepth", true)}{sizes ? ' (' + sizes.min.depth +'-'+sizes.max.depth + ')' : null}:</label>
              <input
                type="number"
                id="size-z"
                value={size.z}
	              className={'constructor__input'}
                onChange={(e) => handlerChangeSize(e, 'z')}
              />
	            {!empty(errors[correctAxis.z]) ? <span className="constructor__input-error">{Languages.getTranslation(errors[correctAxis.z], true)}</span> : null}
            </div>
		      </div>
	      <ModalFooter className={'d-flex justify-content-between align-items-center'}>
		      <DefaultBtn
			      title={Languages.getTranslation("cancel2", true)}
			      handler={() => setSizeModal(false)}
			      />
		      <DefaultBtn
			      title={Languages.getTranslation("save", true)}
			      handler={saveProductSize}
		      />
	      </ModalFooter>
	      </div>
      </Modal>
    )
	}

	const minMaxHandler = e => {
		const {name, value} = e.target;
		const _tmp = name.split('_')
		setMinMaxSize(state => {
			return {...state, [_tmp[0]]: {...state[_tmp[0]], [_tmp[1]]: Number(value)}}
		})
	}

	const getProductOptionsModal = () => {
		if(!productOptionsModal) return null;
		return (
			<Modal isOpen={productOptionsModal}>
				<div className="modal__wrapper">
					<ModalHeader className={'d-flex justify-content-between align-items-center'}>
						<h3 className="addDetail__header-title">{Languages.getTranslation("product_options", true)}</h3>
						<CloseBtn handler={() => setProductOptionsModal(false)}/>
					</ModalHeader>
					<div className={'modal__body constructor__modal-body'}>
						<div className="d-flex flex-column">
							<DefaultBtn
								className={'mb-2'}
								title={Languages.getTranslation("changeProductSize", true)}
								handler={() => changeProductSize()}
							/>
							{permissions.includes('KK_addSizeLimit') ? <DefaultBtn
								title={Languages.getTranslation("setSizeLimits", true)}
								handler={() => setProductMinMax()}
							/> : null}

						</div>
					</div>
				</div>
			</Modal>
		)
	}

	const getSetSizeModal = () => {
		if(!minMaxSizeModal) return null;
		return (<Modal
			isOpen={minMaxSizeModal}
		>
			<div className={'modal__wrapper constructor__modal-middle'}>
				<ModalHeader className={'d-flex justify-content-between align-items-center'}>
					<h3 className="addDetail__header-title">{Languages.getTranslation("setSizeLimitsModalTitle", true)}</h3>
					<CloseBtn handler={() => setMinMaxSizeModal(false)}/>
				</ModalHeader>
				<div className={'modal__body constructor__modal-body  d-flex flex-column'}>
					<h4 className={'mb-2'}>Мінімальні значення</h4>
					<div className="modal__input-group constructor__input-group  mb-2">
						<label className={'constructor__input-label'} htmlFor="size-x">{Languages.getTranslation("optionsWidth", true)}:</label>
						<input
							type="number"
							id="size-x"
							className={'constructor__input'}
							name={'min_x'}
							value={minMaxSize.min?.x}
							onChange={minMaxHandler}
						/>
					</div>
					<div className="modal__input-group constructor__input-group  mb-2">
						<label className={'constructor__input-label'} htmlFor="size-y">{Languages.getTranslation("optionsHeight", true)}:</label>
						<input
							type="number"
							id="size-y"
							name={'min_y'}
							className={'constructor__input'}
							value={minMaxSize.min?.y}
							onChange={minMaxHandler}
						/>
					</div>
					<div className="modal__input-group constructor__input-group  mb-2">
						<label className={'constructor__input-label'} htmlFor="size-z">{Languages.getTranslation("optionsDepth", true)}:</label>
						<input
							type="number"
							id="size-z"
							name={'min_z'}
							className={'constructor__input'}
							value={minMaxSize.min?.z}
							onChange={minMaxHandler}
						/>
					</div>
					<h4 className={'mb-2'}>Максимальні значення</h4>
					<div className="modal__input-group constructor__input-group  mb-2">
						<label className={'constructor__input-label'} htmlFor="size-x">{Languages.getTranslation("optionsWidth", true)}:</label>
						<input
							type="number"
							id="size-x"
							name={'max_x'}
							className={'constructor__input'}
							value={minMaxSize.max?.x}
							onChange={minMaxHandler}
						/>
					</div>
					<div className="modal__input-group constructor__input-group  mb-2">
						<label className={'constructor__input-label'} htmlFor="size-y">{Languages.getTranslation("optionsHeight", true)}:</label>
						<input
							type="number"
							id="size-y"
							name={'max_y'}
							className={'constructor__input'}
							value={minMaxSize.max?.y}
							onChange={minMaxHandler}
						/>
					</div>
					<div className="modal__input-group constructor__input-group  mb-2">
						<label className={'constructor__input-label'} htmlFor="size-z">{Languages.getTranslation("optionsDepth", true)}:</label>
						<input
							type="number"
							id="size-z"
							name={'max_z'}
							className={'constructor__input'}
							value={minMaxSize.max?.z}
							onChange={minMaxHandler}
						/>
					</div>
				</div>
				<ModalFooter className={'d-flex justify-content-between align-items-center'}>
					<DefaultBtn
						title={Languages.getTranslation("cancel2", true)}
						handler={() => setMinMaxSizeModal(false)}
					/>
					<DefaultBtn
						title={Languages.getTranslation("save", true)}
						handler={saveProductMinMax}
					/>
				</ModalFooter>
			</div>
		</Modal>)
	}

	const renderOptions = () => {
		// if (!optionsVisible) return null;
		const optionsArray = [];
		const selectedDetails = details.filter(detail => detail.selected);
		optionsArray.push({
			type: 'action',
			handler: () => changeProductSize(),
			text: 'changeSize'
		})

		// if(permissions.includes('KK_addSizeLimit')) {
			optionsArray.push({
				type: 'action',
				handler: () => setProductMinMax(),
				text: 'setSize'
			})
		// }

		// if (selectedDetails.length >= 2) {
		// 	optionsArray.push({
		// 		type: 'action',
		// 		handler: () => mergeDetails(selectedDetails),
		// 		text: 'group'
		// 	})
		// }
		return (
			<>
				{optionsArray.map(el => <a onClick={el.handler}>{el.text}</a>)}
			</>
		)
	}

	const getProductOptions = () => {
		return (
			<>
				<a className={'icon-button-burger'} onClick={() => setProductOptionsModal(true)}>

				</a>
				{/*<button className={'icon-button-burger'} onClick={toggleOptions}>...</button>*/}
				{/*{renderOptions()}*/}
			</>
		)
	}


	return (
		<Panel className='used-details p__content' icon={'box'} lock={false} options={getProductOptions}  title={title}>
			{detailsGroups.map((el, i) => {
				if(!empty(el.details)) {
					return (
						<div className={'used-details__group'} key={i}>
							<p className={'used-details__group-title'}>{el.name}</p>
							{el.details.map(detail => <UsedDetail key={detail.detailCId} removeFromGroup={removeFromGroup} detailCId={detail.detailCId}  />)}
						</div>
					)
				} else {
					return el.map(detail => <UsedDetail key={detail.detailCId} detailCId={detail.detailCId}  />)
				}
			})}
			{permissions.includes('KK_editConnections') ? <Connections productId={productId} /> : null}
			<Materials productId={productId} />
			{getEditSizeModal()}
			{getSetSizeModal()}
			{getProductOptionsModal()}
		</Panel>
	)
}

export default UsedDetails