import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import store from "redux/store";
import PrivateTemplate from "views/PrivateTemplate";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
// import {modalsOperations} from "redux/modals";
// import {projectOperations, projectSelectors} from "redux/project";
// import CONSTANTS from "config/constants";
// import {toast} from "react-toastify";
import "./style.scss";
// import {empty, isset} from "../../helpers/helper";
// import DefaultBtn from "../../components/UI/Buttons/DefaultBtn";
// import Languages from "../../translation/Languages";
// import {panelOperations, panelSelectors} from "redux/panel";
// import Errors from "../../models/Errors";
// import Loader from "../../components/UI/Loader";
import ConstructorScene from "../../constructor/view/3dPage";

import {TableRefContextProvider} from "../../contexts";
// const _errors = new Errors([])

const Constructor = () => {
	// const dispatch = useDispatch();
	// const navigate = useNavigate();
	// const construction = useSelector(projectSelectors.getConstruction);
	// const details = construction.details
	// const [processPanel, setProcessPanel] = useState(false)
	// // const [chosenProcesses, setChosenProcesses] = useState(false);
	// // const [selectedProcess, setSelectedProcess] = useState("");
	// const [cameraShow, setCameraShow] = useState(false)
	// const isEditPanel = useSelector(panelSelectors.getPanelIsEditPanel);
	// const panel = useSelector(panelSelectors.getPanelShowState)

	// const detail = useSelector(projectSelectors.getDetail, shallowEqual);
	// const detail = useSelector(projectSelectors.getDetail);
	// const treatment = useSelector(projectSelectors.getTreatment);

	return (
		// <TableRefContextProvider>
			<PrivateTemplate>
						<ConstructorScene/>
			</PrivateTemplate>
		// </TableRefContextProvider>
	);
};

export default Constructor;
