import store from "redux/store";
import Requests from "api/API";
import Languages from "./../translation/Languages";
import {projectOperations} from "redux/project";
import Construction from "./Construction";
import Db from "../api/Db";
import _Edges from "../db/_Edges";
import _Materials from "../db/_Materials";
import _Details from "../db/_Details";
import _Constructor from "../db/_Constructor";
import {empty, isset, sortDetailsByOrder, createDataForProvider} from "../helpers/helper";
import _Project from "../db/_Project";
import {errorMessageOperations} from '../redux/errors'
import Errors from "./Errors";
import Helpers from "./3D/Helpers";
import cloneDeep from "lodash.clonedeep";
import _Products from "../db/_Products";

const db = new Db();
const _materialsDb = new _Materials();
const _edgesDb = new _Edges();
const _detailsDb = new _Details();
const _projectDb = new _Project()
const _productsDb = new _Products()
const _constructorDb = new _Constructor();
const _errors = new Errors([])

const Project = {
  async calcProjectModel(dispatch, user) {
    const phone = user?.phone;
    const ttidFilial = user?.filial?.ttid ?? null;
    const project = await this.getDataJSON();

    const dataForSent = {
      project: JSON.parse(project),
      phone,
      ttidFilial,
    }

    return Requests.Projects.calcProject(dataForSent).then((response) => {
      dispatch(errorMessageOperations.switchStateError(
          { message: Languages.getTranslation("project-calc", true), type: 'success', show: true }))
      return response.data
    }).catch(() => {
      dispatch(errorMessageOperations.switchStateError(
          { message: Languages.getTranslation("project-calc-error", true), type: 'warn', show: true }))
    });
  },

  async reportPdfModel(projectId) {

    const project = await this.getDataJSON();

    const dataForSent = {
      project: JSON.parse(project),
      project_id: projectId
    }

    return Requests.Projects.reportPdf(dataForSent).then((response) => {

      return response.data
    })
        .catch(error => {
          _errors.setError(error.message, 'error', true)
          return Promise.reject({ Error: error })
        })
  },
  async getDetailedReport(projectId) {

      const project = await this.getDataJSON(undefined, true);

      const dataForSent = {
          project: JSON.parse(project),
          project_id: projectId
      }

      return Requests.Projects.getDetailedReport(dataForSent).then((response) => {

          return response.data
      })
          .catch(error => {
              _errors.setError(error.message, 'error', true)
              return Promise.reject({ Error: error })
          })
  },
  getDataJSONImages(stringify= true){
        return Promise.all([
            db.getAllData('details')
        ])
            .then(data => {
                const details = data[0];

                return details.map(det => {
                    return {
                        detail_id: det.id,
                        img_base: det.imgBase,
                        img_pdf: det.imgPdf,
                    }
                })
            })
            .then((data) => {
                if(stringify) {
                    return Promise.resolve(JSON.stringify(data));
                } else {
                    return Promise.resolve(data);
                }
            })
    },

  getDataJSON(stringify = true, withImages = false) {
    return Promise.all([
      db.getAllData('materials'),
      db.getAllData('edges'),
      db.getAllData('details'),
      db.getAllData('furnitures'),
      db.getAllData('project'),
      db.getAllData('products'),
      db.getAllData('cDetails'),
	    db.getAllData('cConnections')
    ])
      .then(data => {
        const _materials = data[0];
        const _edges = data[1];
        const _details = data[2];
        const _furnitures = data[3];
        const _project = data[4];
        const _products = data[5];
        const _cDetails = data[6];
        const _cConnections = data[7];

        _details.sort(sortDetailsByOrder).forEach((_detail, i) => {
          _detail.productId = Number(_detail.productId)
          _detail.id = i + 1;
          if(!withImages){
            delete _detail.imgBase
            delete _detail.imgPdf
          }

          delete _detail.order
          if (!empty(_detail.material)) {
            _detail.materialIndex = _detail.material;
            _detail.material = _materials.findIndex(el => el.index === _detail.material);
          }
          for (const _edge in _detail.edges) {
            if (!empty(_detail.edges[_edge])) {
              _detail.edges[_edge] = _edges.findIndex(el => el.index === _detail.edges[_edge]);
            }
          }
          if(!empty(_detail.rects)) {
            for (const _rect of _detail.rects) {
              if (_rect.type === "Groove" && !_rect.hasOwnProperty('additionalCutter')) {
                _rect.additionalCutter = true;
              }
              if(!empty(_rect.edge) && !empty(_rect.edge.index)) {
                _rect.edge = _edges.findIndex(el => el.index === _rect.edge)
              }
            }
          }
          if(!empty(_detail.mills)) {
            const dX = !empty(_detail.edges.left) ? _edges[_detail.edges.left].thickness : 0;
            const dY = !empty(_detail.edges.bottom) ? _edges[_detail.edges.bottom].thickness : 0;
            _detail.mills.forEach(mill => {
              if(empty(mill.elements)) return;
              if(['tableCorner',].includes(mill.type)) {
                mill.overallElements = cloneDeep(mill.elements);
                mill.length = Helpers.getLengthForMills(mill, _edges)
              }
              else if(['gEdge', 'uShape', 'radiusEdge', 'tableTop',].includes(mill.type)) {
                mill.overallElements = cloneDeep(mill.elements);

                mill.length = Helpers.getLengthForMills(mill, _edges)
                if(dX > 0 || dY > 0) {
                  mill.elements.forEach(el => {
                    el.x1 = el.x1 - dX;
                    el.x2 = el.x2 - dX;
                    el.y1 = el.y1 - dY;
                    el.y2 = el.y2 - dY;
                      if (el.type === 'arc') {
                        el.xc = el.xc - dX;
                        el.yc = el.yc - dY;
                      }
                  })
                }
              }
              else if (['smile'].includes(mill.type)){
                mill.elements.forEach(el => {
                  el.x1 = Number(el.x1.toFixed(2))
                  el.x2 = Number(el.x2.toFixed(2))
                  el.y1 = Number(el.y1.toFixed(2))
                  el.y2 = Number(el.y2.toFixed(2))
                  if (el.type === 'arc') {
                    el.xc = Number(el.xc.toFixed(2))
                    el.yc = Number(el.yc.toFixed(2))
                  }
                })
                mill.overallElements = cloneDeep(mill.elements);
                mill.length = Helpers.getLengthForMills(mill, _edges)
                if (!empty(_detail.edges.bottom)) {
                  if (['right', 'left', 'top'].includes(mill.edgeSide)) {
                    if (dY > 0) {
                      mill.elements.forEach(el => {
                        el.y1 = el.y1 - dY;
                        el.y2 = el.y2 - dY;
                        if (el.type === 'arc') {
                          el.yc = el.yc - dY;
                        }
                      })
                    }
                  }
                }
                if (!empty(_detail.edges.left)) {
                  if (['top', 'bottom', 'right'].includes(mill.edgeSide)) {
                    if (dX > 0) {
                      mill.elements.forEach(el => {
                        el.x1 = el.x1 - dX;
                        el.x2 = el.x2 - dX;
                        if (el.type === 'arc') {
                          el.xc = el.xc - dX;
                        }
                      })
                    }
                  }
                }
              }
              if(!empty(mill.edge)) {
                const edge = _edges.findIndex(el => el.index === mill.edge);
                mill.edge = edge !== -1 ? edge : null;
              }
            })
          }
          if(!empty(_detail.corners)){
            for (const corner of _detail.corners) {
              if(!empty(corner.edge)) {
                corner.edge = _edges.findIndex(el => el.index === corner.edge)
              }
            }
          }
          Helpers.calcDetailPreCutting(_detail, true);
          if(!empty(_detail.contour3d)) {
            for (const contour of _detail.contour3d) {
              if(!empty(contour.edge)) {
                contour.edge = _edges.findIndex(el => el.index === contour.edge);
              }
              if(empty(contour.length)) {
                switch (contour.type) {
                  case 'line':
                    contour.length = Helpers.calculateDistance(contour.x1, contour.y1, contour.x2, contour.y2);
                    break;
                  case 'arc':
                    const angle = Helpers.calculateAngle(
                      {x: contour.xc, y: contour.yc},
                      {x: contour.x1, y: contour.y1},
                      {x: contour.x2, y: contour.y2},
                    )
                    contour.length = Helpers.calculateArcLength(contour.r, angle.angleStart, angle.angleEnd);
                }
              }
            }
          }
          if(!empty(_detail.contour)){
            for (const contour of _detail.contour) {
              if(!empty(contour.edge)) {
                contour.edge = _edges.findIndex(el => el.index === contour.edge)
              }
            }
          }
          if(!empty(_detail.multiplicity)) {
            if(typeof _detail.multiplicity === 'object') {
              _detail.multiplicity.material = _materials.findIndex(el => el.index === _detail.multiplicity.material)
              if(!empty(_detail.multiplicity.edge)) {
                _detail.multiplicity.edge = _edges.findIndex(el => el.index === _detail.multiplicity.edge)
              }
            } else if(_detail.multiplicity > 1){
              _detail.multiplicity = {
                type: 1,
                edge: null,
                material: _detail.material
              }
            } else {
              _detail.multiplicity = null;
            }
          }
          if(!empty(_detail.templates)) {
            _detail.templates.forEach(el => el.type = el.templateId)
          }
          _detail.productId = !empty(_detail.productId) ? Number(_detail.productId) || 1 : 1;
        })
        _materials.forEach(_material => {
          _material.name = _material.name ?? Languages.getAvaliableTranslation(_material.languages);
          _material.height = empty(_material.height) ? parseFloat(_material.l) : parseFloat(_material.height);
          _material.width = empty(_material.width) ? parseFloat(_material.w) : parseFloat(_material.width);
          if (empty(_material.type)) _material.type = _material.typename;
        })
        _edges.forEach(_edge => {
          _edge.name = _edge.name ?? Languages.getAvaliableTranslation(_edge.languages);
          _edge.height = empty(_edge.height) ? parseFloat(_edge.l) : parseFloat(_edge.height)
          _edge.width = empty(_edge.width) ? parseFloat(_edge.h) : parseFloat(_edge.width)
          _edge.thickness = empty(_edge.thickness) ? parseFloat(_edge.t) : parseFloat(_edge.thickness)
          if (empty(_edge.type)) _edge.type = _edge.typename;
        })
        const currentTime = new Date();
        const structure = {
          constructor: {
            name: "KM3D",
            updated: `${currentTime.getDate()}-${currentTime.getMonth() + 1}-${currentTime.getFullYear()}`,
            created: {
              creator : createDataForProvider(_project[0]) || null
            },
            version: 5
          },
          department: 1,
          glue_type: _project[0]?.glue?.name ?? null, //|| 'PUR', //Тип клея EVA, PUR
          glue_color: _project[0]?.glue?.type ?? null, //|| 'transparent', //transparent - прозрачный, white - белый
          glue_id: _project[0]?.glue?.id ?? null,
          materials: _materials,
          products: !empty(_products) ? _products : [{id: 1, name: 'product'}] ,
          edges: _edges,
          details: _details,
          furnitures: _furnitures,
          cDetails: _cDetails,
          cConnections: _cConnections
        }
        return Promise.resolve(structure);
      })
      .then((structure) => {
        if(stringify) {
          return Promise.resolve(JSON.stringify(structure));
        } else {
          return Promise.resolve(structure);
        }
      })
  },


  async saveProject(name, folderName, dispatch) {
    const images = await this.getDataJSONImages();
    return this.getDataJSON(false)
          .then(structure => {
            const {provider_name, provider_version, provider_created_at} = createDataForProvider(structure.constructor.created)
            return Requests.Projects.saveProject(name, folderName, JSON.stringify(structure), JSON.stringify(images), "KMaster", provider_name, provider_version, provider_created_at  )})
          .then((response) => {
            if (response && response.data)
              dispatch(errorMessageOperations.switchStateError(
                  { message: Languages.getTranslation("project-save", true),
                      type: 'success', show: true, autoHide: 'none' }))
            return Promise.resolve(response.data)
          })
          .catch(error => {
            _errors.setError(error.message, 'error', true)
            return Promise.reject({ Error: error })
          })
  },

  async updateProject(name, id, dispatch){
    const images = await this.getDataJSONImages();

    return this.getDataJSON(false)
      .then(structure => Requests.Projects.updateProject(id, name, JSON.stringify(structure), JSON.stringify(images)))
      .then((response) => {
        if (response && response.data){
          const [messageKey, messageType] = response.data?.finalized_at === null ? ["project-save", 'success'] : ["project-save-error", 'error'];
          dispatch(errorMessageOperations.switchStateError({
            message: Languages.getTranslation(messageKey, true),
            type: messageType,
            show: true,
              autoHide: messageKey === 'project-save' ? 'none' : true
          }));
        }

        return Promise.resolve()
      })
      .catch(error => {
          dispatch(errorMessageOperations.switchStateError({
              message: Languages.getTranslation('project-save-error', true),
              type: 'error',
              show: true
          }));
        _errors.setError(error.message, 'error', true)
        return Promise.reject({ Error: error })
      })
  },

  getMapMaterials(dispatch, index = null) {
    return this.getDataJSON(false)
        .then(structure => {
          return Requests.Projects.getMapMaterials(
              {"get_pdf_cutting": !empty(index.material_cutting) ?
                    {...structure, material_cutting: index.material_cutting} : structure})
        })
        .then((response) => {
          return Promise.resolve(response)
        })
        .catch(error => {
          _errors.setError(`${Languages.getTranslation(error.response.data.errors.text, true)}`, 'error', true)
          return Promise.reject(error)
        })
  },

  async editClientProject(name, owner, dispatch) {
    return this.getDataJSON(false)
      .then(structure => {
        const {provider_name, provider_version, provider_created_at} = createDataForProvider(structure.constructor.created)
        return Requests.Projects.editClientProject(name, owner,  JSON.stringify(structure), provider_name, provider_version, provider_created_at)})
      .then((response) => {
        if (response && response.data)
          dispatch(errorMessageOperations.switchStateError(
            { message: `${Languages.getTranslation("edit-client-project-success", true)}, ${Languages.getTranslation("id-of-new-project", true)}: ${response.data.project.id}`, type: 'success', show: true }))
        return Promise.resolve()
      })
      .catch(error => {
        _errors.setError(error.response.data.message, 'error', true)
        return Promise.reject({ Error: error })
      })
  },

  async saveToDb(data) {
    return db.dropAllData()
        .then(() => {
          const { edges, materials, details, furnitures } = data;
          return Promise.all([
              _edgesDb.addEdges([...edges]),
              _materialsDb.addMaterials([...materials]),
              Promise.all(details.map(detail => {
                if(detail.material !== null && materials.length && materials[detail.material]) {
                  detail.w = materials[detail.material].thickness || materials[detail.material].t
                }
                return _detailsDb.addDetail(detail)
              })),
              Promise.all(furnitures.map(furniture => { return db.addItem('furnitures', furniture)}))
          ])
        })
  },

  loadProject(id = null, dispatch) {
    const loadFromDb = empty(id);

    function getData() {
      return loadFromDb
          ? db.detDataFromDb()
          : Requests.Projects.getProjectById(id)
              .catch((error) =>
              dispatch(errorMessageOperations.switchStateError({ message: error.message, type: 'warn', show: true })));
    }

    return new Promise((resolve, reject) => {
      getData()
          .then(data => {
            if(!empty(data)) {
              dispatch(
                  projectOperations.setConstruction(new Construction({}, dispatch))
              );

              if(loadFromDb) {
                return Promise.resolve(data)
              } else {
                const content = JSON.parse(data.file)
                content.projectData = {
                  name: data.name,
                  id: data.id,
                  project_type: data.project_type ?? {id: 1, name: 'standard'},
                  owner: data.owner,
                  created_at: data.created_at,
                  updated_at: data.updated_at,
                  glue: {
                    id: content.glue_id || 0,
                    name: content.glue_type || 'PUR',
                    type: content.glue_color || 'transparent',
                  },
                  creator: content.constructor.created.creator || content.creator || null
                }
                // this.setIdToProcess(content)
                return Promise.resolve(content);
              }
            } else {
              resolve(false)
            }
          })
          .then((data) => {
            const construction = store.getState().project.project.construction;
            if(loadFromDb) {
              construction.projectData = data.projectData;
              construction.edges = data.edges;
              construction.materials = data.materials;
              construction.products = data.products;
              construction.furnitures = data.furnitures;
              if(!empty(data.details)) {
                data.details.forEach((detail, i) => {
                  detail.productId = Number(detail.productId)
                  const material = data.materials.find(el => el.index === detail.material)
                  detail.w = material.thickness || material.t
                  construction.addDetail(detail)
                })
              }
              if (data.projectData.id) construction.checkReservingMaterials(data.projectData.id)
              return Promise.resolve(data);
            } else {
              return db.dropAllData()
                .then(() => {
                  construction.projectData = data.projectData;
                  return _projectDb.addProject(data.projectData)
                })
                .then(() => {
                  if(!empty(data.products) && Array.isArray(data.products)) {
                    return Promise.all(data.products.map(product => construction.createProduct({...product})))
                  } else {
                    return construction.createProduct({name: "product"})
                  }
                })
                  .then(() => construction.setProjectMaterials([...data.materials]))
                  .then(() => construction.setProjectFurnitures([...data.furnitures]))
                  .then(() => {
                    if (data.projectData.id) {
                      return  construction.checkReservingMaterials(data.projectData.id)
                    }
                    return Promise.resolve(data)
                  })
                  .then(() => construction.setProjectEdges([...data.edges], true))
                  .then(() => {
                      data.details.forEach((_detail) => {
                        _detail.rects.forEach(el =>{
                          if (el.type === 'Groove' && !el.hasOwnProperty('additionalCutter')) {
                            el.additionalCutter = true
                          }
                        })
                        _detail.productId = Number(_detail.productId)
                       delete _detail.order
                      })
                      return construction.addProjectDetails([...data.details])
                  })
		              .then(() => {
			              if(!empty(data?.cDetails)) {
				              return Promise.all(data.cDetails.map(cDetail => _constructorDb.addDetail(cDetail)))
			              } else {
				              return Promise.resolve()
			              }

		              })
                  .then(() => {
                    if(!empty(data?.cConnections)) {
                      return Promise.all(data.cConnections.map(cConnection => _constructorDb.addConnection(cConnection)))
                    } else {
                      return Promise.resolve()
                    }
                  })
                  .then(() => Promise.resolve(data))
            }
          })
          .then((data) => {
            const construction = store.getState().project.project.construction;
            return new Promise((resolve, reject) => {

              const addDetailRects = (detail, detailData) => {
                if(!empty(detailData.rects)) {
                  const rects = [...detailData.rects];
                  detail.rects = [];
                  rects.forEach(rect => {
                    rect.isInit = true;
                    switch (rect.type) {
                      case "Circle":
                        rect.subType = 'circle';
                        const _circle = detail.addCircle(rect);
                        _circle.isInit = false;
                        break;
                      case "Groove":
                        const _groove = detail.addGroove(rect);
                        _groove.isInit = false;
                        break;
                      case "Rabbet":
                        const _rabbet = detail.addRabbet(rect);
                        _rabbet.isInit = false;
                        break;
                      case "Rectangle":
                        const _rectangle = detail.addRectangle(rect);
                        _rectangle.isInit = false;
                        break;
                    }
                  })
                }
                return Promise.resolve()
              }

              const addDetailCorners = (detail, detailData) => {
                if (!empty(detail.corners)) {
                  const corners = [...detailData.corners];
                  detail.corners = [];
                  corners.forEach((corner) => {
                    const ignored = detailData.mills.some(mill => {
                      return mill.elements.some(el => {
                        if(!empty(corner.contourId) && corner.contourId === el.id) {
                          return true;
                        }
                      })
                    })
                    if(!ignored) {
                      if(!empty(corner.edge) && corner.edge < 0){
                        corner.edge = null
                        detail.error.addError(Languages.getTranslation("cheak-edge", true), 'warning')
                      }
                      corner.isInit = true;
                      const _corner = detail.addCorner(corner);
                      _corner.isInit = false;
                    }

                  });
                }
                return detail.updateDb('corners', detail.corners.map(el => ({...el, edge: typeof el.edge === 'object' && !empty(el.edge) ? el.edge.index : el.edge}))).then(() =>Promise.resolve())
              }

              const addDetailHoles = (detail, detailData) => {
                if (!empty(detailData.holes)) {
                  const holes = [...detailData.holes];
                  detail.holes = [];
                  holes.forEach((hole) => {
                    hole.isInit = true;
                    // if(hole.diam === 4.5 && (hole.side === 'front' || hole.side === 'back')){ hole.diam = 5 }
                    const _hole = detail.addHole(hole);
                    _hole.isInit = false;
                  });
                }
                return Promise.resolve()
              }

              const addDetailMills = (detail, detailData) => {
                return new Promise((resolve1, reject1) => {
                  if(!empty(detailData.mills)) {
                    const mills = [...detailData.mills];
                    detail.mills = [];

                    const addMill = (i) => {
                      const mill = mills[i];
                      if(!empty(mill?.edge) && mill.edge < 0){
                        mill.edge = null
                        detail.error.addError(Languages.getTranslation("cheak-edge", true), 'warning')
                      }
                      if(isset(mill)) {
                        mill.isInit = true;
                        mill.subType = 'mill';
                        if(!['partial', 'closed'].includes(mill.type)) {
                          mill.elements = [];
                        }
                        if (['tableTop', 'tableCorner'].includes(mill.type)) {
                          if(!empty(mill?.additionalTreats)) {
                            const template = {
                              name: Languages.getTranslation("Tabletop-lock", true),
                              subType: "tableProc",
                              templateId: "tableTop",
                              isTableTop: true,
                              templateData: {
                                edgeSide: mill.edgeSide,
                                width: mill.width ?? 600,
                                additionalSide: mill.additionalSide ?? 'left',
                              },
                              additionalTreats: {...mill.additionalTreats, mills: [mill.id]}
                            }
                            delete mill.additionalTreats;
                            detailData.templates.push(template)
                          }
                          const _mill = detail.addTableProc(mill)
                            _mill.updateDb()
                            .then(() => {
                              _mill.isInit = false;
                              addMill(i + 1)
                            })
                            .catch(e => reject1(e))
                        } else {
                          const _mill = detail.addMill(mill)
                          if (_mill.shouldRemoved) {
                            _mill.remove()
                              .then(() => {
                                _mill.isInit = false;
                                return addMill(i + 1)
                              })
                              .catch(e => reject1(e))
                          } else {
                            _mill.updateDb()
                              .then(() => {
                                _mill.isInit = false;
                                return addMill(i + 1)
                              })
                              .catch(e => reject1(e))
                          }
                        }
                      } else {
                        resolve1();
                      }
                    }
                    addMill(0)
                  } else {
                    resolve1();
                  }
                })

              }

              const addDetailBevels = (detail, detailData) => {
                if(!empty(detailData.bevels)) {
                  const bevels = [...detailData.bevels]
                  detail.bevels = [];
                  bevels.forEach(bevel => {
                    bevel.isInit = true;
                    const _bevel = detail.addBevel(bevel)
                    _bevel.isInit = false;
                  });
                }
                return Promise.resolve();
              }

              const addDetailTemplates = (detail, detailData) => {
                return new Promise((resolve1, reject1) => {
                  if(!empty(detailData.templates)) {
                    const templates = [...detailData.templates]
                    detail.templates = [];

                    const addTemplate = (i) => {
                      const template = templates[i];
                      if(!empty(template)) {
                        template.isLoad = true;
                        template.isInit = true;
                        if(!empty(template.type)) {
                          template.templateId = template.type;
                          delete template.type;
                        }
                        const t = detail.addTemplates(template)
                        t.updateDb()
                          .then(() => {
                            // t.isInit = false;
                            // t.isLoad = false;
                            addTemplate(i + 1)
                          })
                          .catch(e => reject1(e))
                      } else {
                        resolve1()
                      }
                    }
                    return addTemplate(0);
                  } else {
                    resolve1();
                  }
                })

              }

              const addDetailCutOuts = (detail,  detailData) => {

                if(!empty(detailData.cutouts)) {
                  const cutouts = [...detailData.cutouts]
                  detail.cutouts = [];
                  cutouts.forEach(cutout => {
                    cutout.isInit = true;
                    if (cutout.type === 'Sampling') {
                      const _cutout = detail.addCutOutSampling(cutout)
                      _cutout.isInit = false;
                    } else {
                      const _cutout = detail.addCutOut(cutout)
                      _cutout.isInit = false;
                    }
                  });
                }
                return Promise.resolve();
              }

              const proccesing = (iteration) => {
                const detail = construction.details[iteration];
                if(!empty(detail)) {
                  const detailData = data.details.find(el => el.id === detail.id)
                  addDetailRects(detail, detailData)
                    .then(() => addDetailMills(detail, detailData))
                    .then(() => addDetailCorners(detail, detailData))
                    .then(() => addDetailHoles(detail, detailData))
                    .then(() => addDetailBevels(detail, detailData))
                    .then(() => addDetailCutOuts(detail, detailData))
                    .then(() => addDetailTemplates(detail, detailData))
                    .then(() => {
                      if (!empty(detail.multiplicity) && typeof detail.multiplicity !== 'object' && Number(detail.multiplicity) > 1) {
                        detail.multiplicity = {type: 1, edge: null, material: detail.material}
                      }
                      // if(!loadFromDb) {
                        detail.createContour();
                      if(!detail.isPreCuttingManually){
                          detail.checkIfPreCuttingNeeded()
                      }
                      // }
                      proccesing(iteration + 1)
                    })
                    .catch(e => reject(e))
                } else {
                  resolve()
                }
              }
              proccesing(0)
            })

          })
          .then(() => {
            if(!loadFromDb) {
              dispatch(errorMessageOperations.switchStateError(
                  { message: Languages.getTranslation("project-success-load", true), type: 'success', show: true }))
                // TODO remove id params from URL
                let url = new URL(window.location.href);
                let params = new URLSearchParams(url.search);

                params.delete('id');
                url.search = params.toString();

                window.history.pushState({}, '', url.toString());
                url.search = params.toString();
            }
            resolve(true)
          })
          .catch(err => reject(err))
    })
  },

  async validateProjectDetails() {
    const { details } = store.getState().project.project.construction

    /**
     * Function for switching based on item name and performing specific actions.
     *
     * @param {object} item - The item to be switched.
     * @param {object} tempDetail - The temporary detail object.
     */
    const switching = (item, tempDetail) => {
      let tempProcce, tempIndex;
      switch(item.name){
        case 'Sampling':
          tempIndex = tempDetail.cutouts.findIndex(f => f.id === item.uidOperation)
          tempProcce = tempDetail.cutouts[tempIndex]
          if(empty(tempDetail.cutouts) || empty(tempProcce)){
            return
          }
          tempProcce.isErrorText = item.message
          tempDetail.error.addError(`${item.message} ${Languages.getTranslation(`add_cutoutSampling_title`, true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, tempProcce.id);
          break;
        case 'Circle':
          tempIndex = tempDetail[`rectangles`].findIndex(f => f.id === item.uidOperation)
          tempProcce = tempDetail[`rectangles`][tempIndex]
          if(empty(tempDetail[`rectangles`]) || empty(tempProcce)){
            return
          }
          tempProcce.isErrorText = item.message
          tempDetail.error.addError(`${item.message} ${Languages.getTranslation('circle-proc', true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, tempProcce.id)
          break;
        case 'DetailDimensions':
          tempDetail.error.addError(item.message, item.type, item.index,)
          break;
        case 'holes':
          tempIndex = tempDetail[`holes`].findIndex(f => f.id === item.uidOperation)
          tempProcce = tempDetail[`holes`][tempIndex]
          if(empty(tempDetail[`holes`]) || empty(tempProcce)){
            return
          }
          tempProcce.isErrorText = item.message
          tempDetail.error.addError(`${item.message} ${Languages.getTranslation('hole', true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, tempProcce.id)
          break;
        case 'corners':
          tempIndex = tempDetail[`corners`].findIndex(f => f.id === item.uidOperation)
          tempProcce = tempDetail[`corners`][tempIndex];
          if(empty(tempDetail[`corners`]) || empty(tempProcce)){
            return
          }
          tempProcce.isErrorText = item.message
          tempDetail.error.addError(`${item.message} ${Languages.getTranslation('corner', true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, tempProcce.id)
        break;
        case 'groove':
          tempIndex = tempDetail[`grooves`].findIndex(f => f.id === item.uidOperation)
          tempProcce = tempDetail[`grooves`][tempIndex];
          if(empty(tempDetail[`grooves`]) || empty(tempProcce)){
            return
          }
          tempProcce.isErrorText = item.message
          tempDetail.error.addError(`${item.message} ${Languages.getTranslation('groove', true)}: ${tempIndex + 1}`, item.type, item.index, item.uidOperation, tempProcce.id)
          break;
        case 'Rectangle':
          tempIndex = tempDetail[`rectangles`].findIndex(f => f.id === item.uidOperation)
          tempProcce = tempDetail[`rectangles`][tempIndex];
          if(empty(tempDetail[`rectangles`]) || empty(tempProcce)){
            return
          }
          tempProcce.isErrorText = item.message
          tempDetail.error.addError(`${item.message} ${Languages.getTranslation('rectangle', true)}: ${tempIndex + 1}`, item.type, item.index, item.uidOperation, tempProcce.id)
          break;
        case 'mills':
          tempIndex = tempDetail[`mills`].findIndex(f => f.id === item.uidOperation)
          tempProcce = tempDetail[`mills`][tempIndex];
          if(empty(tempDetail[`mills`]) || empty(tempProcce)){
            return
          }
          tempProcce.isErrorText = item.message
          tempDetail.error.addError(item.message, item.type, item.index, item.uidOperation, tempProcce.id)
          break;
        case 'bevels':
          tempIndex = tempDetail[`bevels`].findIndex(f => f.id === item.uidOperation)
          tempProcce = tempDetail[`bevels`][tempIndex];
          if(empty(tempDetail[`bevels`]) || empty(tempProcce)){
            return
          }
          tempProcce.isErrorText = item.message
          tempDetail.error.addError(item.message, item.type, item.index, item.uidOperation, tempProcce.id)
          break;
        case 'rabbet':
          tempIndex = tempDetail[`rabbets`].findIndex(f => f.id === item.uidOperation)
          tempProcce = tempDetail[`rabbets`][tempIndex]
          if(empty(tempDetail[`rabbets`]) || empty(tempProcce)){
            return
          }
          tempProcce.isErrorText = item.message
          tempDetail.error.addError(item.message, item.type, item.index, item.uidOperation, tempProcce.id)
          break;
        case 'edges':
          tempDetail.error.addError(item.message, item.type, item.index)
          break;
        case "Common":
          tempDetail.error.addError(item.message, item.type, item.index)
          break;
        case 'multiplicity':
          tempDetail.error.addError(item.message, item.type, item.index)
          break;
        default:
          break;
      }
    }
    await Requests.Detail.validate()
      .then(res => {
        details.forEach(el => el.error.dropErrors())
        try{
          const errArr = res.errors
          if(!empty(errArr)){
            for (const operation in errArr){
              const tempDetail = details[operation]
              const tempArr = errArr[operation]
              for(const i in tempArr){
                if(Array.isArray(tempArr[i])) {
                  tempArr[i].forEach(dep => {
                    if (Array.isArray(dep)) {
                      dep.forEach(gg => {
                        if(Array.isArray(gg)){
                          gg.forEach(wtf => { switching(wtf, tempDetail) })
                        }else{ switching(gg, tempDetail) }
                      })
                    }
                  })
                }
                else{
                  const depArr = tempArr[i]
                  for(const dep in depArr){
                    depArr[dep].forEach(dd => {
                      try{
                        switching(dd, tempDetail)
                    }catch (error){
                      console.log(error)
                    }
                    })
                  }
                }
              }
            }
          }
        } catch(err){ console.log(err) }
      })
  },

  checkMaterialsKronasElement() {

  },

  getProjectId() {
    return _projectDb.getAllData('project')
  }

};

export default Project;
