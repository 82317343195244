import "./style.scss";

const DefaultBtn = ({ handler, title, disabled, minWidth='25px', className='', btnStyle }) => {
  return (
    <button onClick={handler} className={`defaultBtn ${className}`} disabled={disabled} style={{minWidth, ...btnStyle}}>
      {title}
    </button>
  );
};

export default DefaultBtn;
