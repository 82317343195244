import {
	Shape,
	ShapeGeometry,
	ExtrudeGeometry,
	Matrix4,
	Quaternion,
	Object3D,
	Vector3,
	Mesh,
	MeshBasicMaterial,
	EdgesGeometry,
	LineBasicMaterial,
	CylinderGeometry,
	BoxGeometry,
	Euler,
	Line,
	Box3, Group, BufferGeometry, LineSegments
} from "three";

import store from "../../redux/store";
import {empty} from "../../helpers/helper";
import {v4 as uuidv4} from "uuid";
import Helper from "./3d/Helper";
import HistoryClass from "./components/HistoryClass";
import ConnectDetails from "./components/ConnectDetails";
import _Constructor from "../../db/_Constructor";
import Base from "./components/Base";

const BaseClass = new Base();
const ConnectClass = new ConnectDetails();

export default class CDetail {
	ShapeGeometrySegments = 32

	_position = {
		x: 0,
    y: 0,
    z: 0
	}
	_rotation = {
		x: 0,
		y: 0,
		z: 0
	}
	_l;
	_h;
	_contour;
	_w;
	detailId = null;
	name;
	scene;
	mesh;
	selected = false;
	isMain = false;
	productId = 1;
	detailCId;
	getEdgeData;
	history;
	view = 'constructor';
	connections = [];
	material = {
		color: {
			inActive: '#c9c8c8',
			active: '#7fd87c',
			main: '#f00'
		}
	}
	isLoad = false;
	edgesColor = {

	}
	processingTypes = {
		hole: ['holes'],
		saw: ['grooves', 'rabbets'],
		cutter: ['rectangles', 'circles']
	}
	locked = false
	errors = {};
	db = new _Constructor();
	group = null;
	_renderType = 1;
	_groupId = null;
	dimension = {};
	axisDefault = {};
	processing = [];
	defProcessing = ['hole'];
	_lockedProcessing = {};
	hidden = false;
	zeroValues = {}
	rotations;
	detailMaterial;
	defMarkerAxis = {
		x: 10,
		y: 5,
		z: 2
	}

	constructor({
		            l,
		            h,
		            w,
		            name,
		            productId,
		            detailId = null,
		            detailCId=null,
		            contour3d=[],
		            number = 1,
		            position= {x: 0, y: 0, z: 0},
		            rotation= {x: 0, y: 0, z: 0},
		            getEdgeData,
								isLoad = false,
								group = null,
		            lockedProcessing = {},
		hidden = false,
		rotations = []
	}) {
		this._l = l;
    this._h = h;
    this._contour = contour3d;
    this._position = position;
    this._rotation = rotation;
		this.name = name;
		this._w = w;
		this.detailCId = detailCId || uuidv4();
		this.detailId = detailId;
		this.scene = store?.getState().constructor3d?.scene?.sceneClass;
		this.productId = productId;
		this.getEdgeData = getEdgeData;
		this.history = new HistoryClass();
		this.isLoad = isLoad;
		this.group = group;
		this.processing = this.getDetailProcessing(['holes']);
		this.lockedProcessing = lockedProcessing;
		this.hidden = hidden;
		this.rotations = rotations;
		if(!this.isLoad) {
			this.addHistoryState('created', {x: 0, y: 0, z: 0}, {x: 0, y: 0, z: 0}, {
				type: 'created',
				from: {x: 0, y: 0, z: 0},
				to: {x: 0, y: 0, z: 0}
			})
			this.db.addDetail(this.dbData)
				.then(() => this.scene.addDetail(this.detailCId))
				.then(() => this.scene.render())
		} else {

		}
		this.detailMaterial = this.getProjectDetail()?.material
		return this;
	}

	addRotations(rotation) {
		this.rotations.push(rotation);
	}

	get dbData() {
		return {
			detailCId: this.detailCId,
			l: this.l,
			h: this.h,
			w: this.w,
			name: this.name,
			detailId: this.detailId,
			rotation: this.rotation,
			position: this.position,
			connections: this.connections,
			productId: this.productId,
			group: this.group,
			lockedProcessing: this.lockedProcessing,
			hidden: this.hidden,
			rotations: this.rotations
		}
	}

	updateProjectDetailParams() {
		const projectDetail = this.getProjectDetail();
		if(empty(projectDetail)) return;
		this.getEdgeData = projectDetail.getEdgeData.bind(projectDetail)
		this.updateSizeParams(projectDetail.l, projectDetail.h);
		this.scene.render();
	}

	load() {
		if(!empty(this.connections)) {
			this.locked = true
		}
		this.isLoad = false;
		this.updateProjectDetailParams()
	}



	updatePositionFromMesh() {
		this.position = this.mesh.position;
	}

	create3DDetail() {
		this.createMesh();
		// let box = new Box3().setFromObject(this.mesh);
		//
		// let center = box.getCenter(new Vector3());
		// this.mesh.position.add(this.position);
		// this.mesh.rotation.setFromQuaternion(this.rotation)
		this.mesh.updateMatrixWorld(true);

		for(const [type, val] of Object.entries(this.processingShoved)) {
			if(val) this.createProcessing(type);
		}
		this.createDefProcessing();
		for(const [axis, val] of Object.entries(this.position)) {
			this.mesh.position[axis] = val
		}
		this.mesh.setRotationFromEuler(new Euler(...Object.values(this.rotation)));
		return this.mesh;
	}

	changeView(show, type) {
		if(this.defProcessing.includes(type)) {
			this.mesh.children.forEach(el => {
				if(el.userData?.isPocessing && this.processingTypes[type].includes(this.getProcessingArrayIndex(el.userData.type))) {
					el.material.setValues({visible: show, opacity: 1})
				}
			})
			this.mesh.children.filter(el => el.userData.meshType === 'layer' || el.userData.meshType === 'side').forEach(el => {
				el.material.setValues({
					opacity: show ? 0.3 : 0.8
				})
			})
		} else {
			if(show) {
				this.createProcessing(type);
			} else {
				this.deleteProcessing(type)
			}
		}

		this.scene.render();
	}

	getMeshColor() {
		if(this.isMain) {
			return this.material.color.main;
		} else if(this.selected) {
      return this.material.color.active;
    } else {
      return this.material.color.inActive;
    }
	}

	getMeshMaterial(color=null, opacity = 0.8, visible=true) {
		return new MeshBasicMaterial({
			color: color || this.getMeshColor(),
			transparent: opacity < 1,
			opacity,
			visible: this.hidden ? false : visible
		})
	}

	getProjectDetail() {
		return BaseClass.getProjectState('details').find(el => el.detailId === this.detailId);
	}


	updateRealProcessing(mesh, globalPos) {
		mesh.updateMatrixWorld(true)
		const pId = mesh?.userData?.pId;
		const isLocked = this.lockedProcessing[pId];
		const processing = this.processing.find(el => el.id === pId);
		const _processing = {};
		const pos = mesh.getWorldPosition(new Vector3())
		const newCords = {};
		['x', 'y', 'z'].forEach(a => {
			newCords[a] = this.zeroValues[a] === 'max' ? Math.abs(globalPos.max[a] - pos[a]) : Math.abs(pos[a] - globalPos.min[a])
		})
		if(isLocked) {
			const lockedAxis = Object.keys(isLocked);
			lockedAxis.forEach(el => {
				if(newCords?.[el]) {
					delete newCords[el];
				}
			})
		}
		// console.log(newCords, this.axisDefault, this.dimension)
		if(!empty(newCords)) {
			for(const [axis, value] of Object.entries(newCords)) {
				switch (this.axisDefault[axis]) {
					case 'x':
						if(!['left', 'right'].includes(processing.side)) {
							_processing.x = processing.x_axis === 'left' ? Number(value.toFixed(1)) : this[this.dimension[axis]] - Number(value.toFixed(1));
						}
						break;
					case 'y':
						if(!['top', 'bottom'].includes(processing.side)) {
							_processing.y = processing.y_axis === 'bottom' ? Number(value.toFixed(1)) : this[this.dimension[axis]] - Number(value.toFixed(1));
							// _processing.y_axis = 'bottom';
						}

						break;
				}
			}
		}
		// console.log({x: processing.x, y: processing.y, x_axis: processing.x_axis, y_axis: processing.y_axis}, _processing);
		// return Promise.resolve();
		return processing.updatePosition(_processing);
		// const {x, y, side} = _processing;

	}

	/**
	 * Updates the project detail data with the provided information.
	 *
	 * @return {Promise} A Promise that resolves when the project detail has been successfully updated.
	 */
	updateProjectDetail() {
		const prDetail = this.getProjectDetail();
		const globalPos = new Box3().setFromObject(this.mesh);
		if(!empty(prDetail)) {
			const updatedProcessing = this.mesh.children.filter(el => el.userData?.isPocessing && ['hole'].includes(el.userData.type));
			// console.log(updatedProcessing)

			return Promise.all(updatedProcessing.map(el => this.updateRealProcessing(el, globalPos)))
				.then(() => prDetail.parent.updateDetailData(prDetail.id, {l: this.l, h: this.h}))
				.then((detail) => {
					const {contour} = detail.createContour()
					this.contour = contour.contour;
					return Promise.resolve()
				})
				.catch(err => console.log(err))
		} else {
			return Promise.reject('Project detail not found')
		}
	}

	addLine = ({x1, y1, x2, y2, holes}, color = null, side) => {
		const mesh = Helper.getLineMesh(
			{ x1, y1, x2, y2, holes:[], w: this.w},
			color, side, true
		)
		mesh.position.x = this.l / 2 - (this.l - x1);
		mesh.position.y = this.h / 2 - (this.h - y1);
		mesh.rotation.z = Math.atan2(y2-y1, x2-x1) - Math.PI / 2
		mesh.position.z = - this.w / 2;
		return mesh
	}

	addArc = ({x1, y1, x2, y2, xc, yc, r, dir}, color = null, side) => {
		const mesh = Helper.getArcMesh(
			{x1, y1, x2, y2, xc, yc, r, dir, w: this.w},
			color,
			side,
			true
		)
		mesh.position.x = -this.l / 2;
		mesh.position.y = -this.h / 2;
		mesh.position.z = - this.w / 2;
		return mesh;
	}

	createEdges() {
		const leftY = [];
		const rightY = [];
		const sides = ['left', 'top', 'right', 'bottom']
		const edgesMesh = [];



		this.contour.forEach(el => {
			if (!el) {
				return
			}
			if(el.x1 === 0) {
				leftY.push(el.y1);
			}
			if(el.x1 >= this.l - 1) {
				rightY.push(el.y1)
			}
		})
		leftY.sort((a, b) => a - b)
		rightY.sort((a, b) => a - b)

		const startTop = this.contour.findIndex(el => el?.x1 === 0 && el?.y1 === leftY[leftY.length - 1])
		const startRight = this.contour.findIndex(el => el?.x1 >= this.l - 1 && el?.y1 === rightY[rightY.length - 1])
		const startBottom = this.contour.findIndex(el => el?.x1 >= this.l - 1 && el?.y1 === rightY[0])

		const getSideContour = (side) => {
			switch (side) {
				case 'left':
					return this.contour.slice(0, startTop);
				case 'top':
					return this.contour.slice(startTop, startRight);
				case 'right':
					return this.contour.slice(startRight, startBottom);
				case 'bottom':
					return this.contour.slice(startBottom);
			}
		}
		const lines = [];
		sides.forEach(side => {
			const points = getSideContour(side);
			let mesh;
			let lineStart, lineEnd;
			points.forEach((contour, i) => {
				if(i === 0) {
					lineStart = new Vector3(contour.x1, contour.y1, 0)
				}
				if(i === points.length - 1) {
					lineEnd = new Vector3(contour.x2, contour.y2, 0)
				}
				switch (contour.type) {
					case 'line':
						if(!empty(contour.edge)) {
							mesh = this.addLine(contour, contour.color ?? Helper.getColorByThickEdge(this.getEdgeData(contour.edge).thickness), side)
						} else {
							mesh = this.addLine(contour, contour.color ?? null, side)
						}
						mesh.userData.type = 'line'
						break;
					case 'arc':
						if(!empty(contour.edge)) {
							mesh = this.addArc(contour, contour.color ?? Helper.getColorByThickEdge(this.getEdgeData(contour.edge).thickness), side)
						} else {
							mesh = this.addArc(contour, contour.color, side)
						}
						mesh.userData.type = 'arc'
						break
				}
				mesh.userData = {
					...mesh.userData,
					isSelected: false,
					selection: true,
					name: `${this.name} торець ${side}`,
					meshType: 'side',
					id: `${this.detailCId}_${side}_side`,
					color: !empty(contour.edge) ? Helper.getColorByThickEdge(this.getEdgeData(contour.edge).thickness) : Helper.materialColor,
					meshSide: side
				}
				edgesMesh.push(mesh)
			})
			const lineGeometry = new BufferGeometry().setFromPoints([lineStart, lineEnd]);
			const line = new Line(lineGeometry, new LineBasicMaterial({color: Helper.contourLinesColor}))
			line.userData = {
				isSelected: false,
				meshSide: side,
				color: Helper.contourLinesColor,
				name: 'грань ' + side,
				meshType: 'line',
				selection: true,
			}
			lines.push(line)
		})
		return {edgesMesh, lines};
	}

	createShape(elements) {
		const shape = new Shape();
		shape.moveTo(elements[0].x1, elements[0].y1);
		elements.forEach(c => {
			if(empty(c)) return;
			switch (c.type) {
				case 'line':
					shape.lineTo(c.x2, c.y2);
					break
				case 'arc':
					shape.absarc(c.xc, c.yc, c.r, c.startAngleRad, c.endAngleRad, c.dir);
					break
			}
		})
		return shape;
	}

	/**
	 * Creates a mesh based on the given parameters and render type.
	 * @return {void}
	 */
	createMesh() {
		const squareShape = this.createShape(this.contour)
		switch (this.renderType) {
			case 1:
				const processingShoved = Object.values(this.processingShoved).some(value => !!value);
				const cutOutMeshes = this.addCutOuts(squareShape, 'front')
				const {edgesMesh, lines} = this.createEdges();
				let geometry = new ShapeGeometry(squareShape)
				const front = new Mesh(geometry, this.getMeshMaterial(Helper.materialColor, processingShoved ? 0.1 : 0.8))
				front.position.z = this.w / 2;
				front.position.x = -this.l / 2;
				front.position.y = -this.h / 2;
				front.userData = {...front.userData,
					isSelected: false,
					meshSide: 'front',
					name: `${this.name} пласть front`,
					color: this.getMeshColor(),
					meshType: 'layer',
					selection: true,
					id: `${this.detailCId}_front_layer`
				};


				const back = new Mesh(geometry, this.getMeshMaterial(Helper.materialBackColor, processingShoved ? 0.1 : 0.8))

				back.position.z = -this.w / 2;
				back.position.x = -this.l / 2;
				back.position.y = -this.h / 2;
				back.scale.set(-1, 1, 1);
				back.rotation.y = Math.PI
				back.userData = {...back.userData,
					isSelected: false,
					meshSide: 'back',
					name: `${this.name} пласть back`,
					color: this.getMeshColor(),
					meshType: 'layer',
					selection: true,
					id: `${this.detailCId}_back_layer`
				};

				this.mesh = new Group()
				this.mesh.add(front);
				this.mesh.add(back);

				edgesMesh.forEach(el => {
					this.mesh.add(el)
				})

				cutOutMeshes.forEach(mesh => this.mesh.add(mesh));
				const {x, y, z} = this.defMarkerAxis;
				const markerGeometry = new BoxGeometry(x, y, z);
				const marker = new Mesh(markerGeometry, this.getMeshMaterial('#029'))
				marker.position.z = -this.w / 2 + z/2;
				marker.position.x = -this.l / 2 + x/2;
				marker.position.y = -this.h / 2 + y/2;
				marker.userData = {
					isDefMarker: true
				}
				this.mesh.add(marker)
				break;
			case 2:
				// let _geometry = new ExtrudeGeometry( squareShape, {
				// 	steps: 1,
				// 	depth: this.w,
				// 	bevelEnabled: false,
				// } )
				let _geometry = new ExtrudeGeometry(squareShape, {steps: 1, depth: this.w, bevelEnabled: false})
				// const _geometry = new BoxGeometry(this.l, this.h, this.w);
				_geometry.center()
				this.mesh = new Mesh(_geometry, [this.getMeshMaterial('#525050', 0.9), this.getMeshMaterial('#373636', 0.9)])
				this.mesh.position.z = -this.w / 2;
				this.mesh.position.x = -this.l / 2;
				this.mesh.position.y = -this.h / 2;
				break;
		}



		this.mesh.userData = {
			detailCId: this.detailCId,
			isMain: this.isMain,
			detailName: this.name,
			number: 1};
	}

	addCutOuts(shape, side) {
		const meshes = [];
		this.getDetailProcessing(['rectangles', 'circles'])
			.forEach(el => {
				if(el.side === side) {
					const mesh = new Group();
					const elements = el.cutOut.elements.map(element => {
						const nElement = {...element,
							x1: element.x1 + el.x,
							x2: element.x2 + el.x,
							y1: element.y1 + el.y,
							y2: element.y2 + el.y
						}
						if(!empty(element.xc)) {
							nElement.xc = element.xc + el.x;
							nElement.yc = element.yc + el.y;
						}
						return nElement
					})
					const cShape = this.createShape(elements)
					shape.holes.push(cShape)
					elements.forEach(cel => {
						switch (cel.type) {
							case 'line':
								mesh.add(this.addLine(cel))
								break;
							case 'arc':
								mesh.add(this.addArc(cel))
								break;
						}
					})
					meshes.push(mesh)
				}
			});
		return meshes
	}

	/**
	 * Deletes all child objects of the mesh that have userData indicating they are of a specific type.
	 * @param {string} type - The type of objects to be deleted.
	 * @return {void}
	 */
	deleteProcessing(type) {
		// if()
		for (let i = this.mesh.children.length - 1; i >= 0; i--) {
			let el = this.mesh.children[i];
			if(el?.userData?.isPocessing && el?.userData?.type === type) {
				this.mesh.remove(el)
			}
		}
	}

	getProcessingArrayIndex(type) {
		const cl = {
			corner: 'corners',
			circle: 'rectangles',
			rectangle: 'rectangles',
			bevel: 'bevels',
			rabbet: 'rabbets',
			hole: 'holes',
			groove: 'grooves',
			mill: 'mills',
			tableProc: 'mills',
			cutOut: 'cutouts'
		}

		return cl[type];
	}

	getProcessingColor(processing) {
		const r = {
			color: Helper.contourLinesColor,
			opacity: 1,
			visible: false
		};
		const isLocked = !empty(this.lockedProcessing[processing.id])
		const showedType = Object.keys(this.processingTypes).find(key => this.processingTypes[key].includes(this.getProcessingArrayIndex(processing.subType)));
		switch (this.renderType) {
			case 1:
				if(this.processingShoved?.[showedType]) {
					r.visible = true
				}
				if(isLocked) {
					r.color = Helper.materialHoColor
				}
				break;
		}
		return r;
	}

	createDefProcessing() {
		this.processing.forEach((el, i) => {
			const {color, opacity, visible} = this.getProcessingColor(el)
			let mesh;
			switch (el.subType) {
				case 'hole':

					const hole = new CylinderGeometry(el.diam / 2, el.diam / 2, el.depth, 32,);
					mesh = new Mesh(hole, this.getMeshMaterial(color, opacity, visible))
					el.calcMesh(mesh)
					break
			}

			if(!empty(mesh)) {
				mesh.userData = {
					...mesh.userData,
					isPocessing: true,
					pId: el.id,
					type: el.subType,
					side: el.side,
					selection: true,
					meshType: el.subType,
					meshSide: 'hole',
					name: `${this.name} hole ${i}`,
					color: color
				}
				this.mesh.add(mesh)
			}
		})

	}

	lockProcessing(pId, axis) {
		const pr = this.lockedProcessing[pId];
		if(pr) {
			if(this.lockedProcessing[pId]?.[axis]) {
				delete this.lockedProcessing[pId][axis]
			} else {
				this.lockedProcessing[pId][axis] = true;
			}
 		} else {
			this.lockedProcessing[pId] = {[axis]: true}
		}
		if(empty(this.lockedProcessing[pId])) {
			delete this.lockedProcessing[pId];
		}


		this.db.updateDetail(this.dbData, 'lockedProcessing')
	}

	highlightChild(pId, type, set=true) {
		let mesh;
		switch (type) {
			case 'processing':
				mesh = this.mesh.children.find(el => el?.userData?.pId === pId)
				break;
			case 'side':
				mesh = this.mesh.children.find(el => el?.userData?.meshSide === pId)
				break
		}
		if(set) {
			if(!mesh?.userData?.colorChanged) {
				mesh.userData.colorChanged = true;
				mesh.userData.prewColor = mesh.material.color.getHexString();
				mesh.userData.prewOpacity = mesh.material.opacity;
			}
			mesh.material.setValues({color: Helper.colorActive, opacity: 0.8})
		} else {
			mesh.material.setValues({color: '#' + mesh.userData?.prewColor, opacity: mesh.userData?.prewOpacity})
			delete mesh.userData.prewColor;
			delete mesh.userData.prewOpacity;
			delete mesh.userData.colorChanged;

		}
		this.scene.render()
	}

	highlightDetail(set = true) {
		this.mesh.children.forEach(el => {
			if(el.userData?.meshType === 'layer' || el.userData?.meshType === 'side') {
				const color = set ? Helper.colorSelected : el.userData?.prewColor;
				const opacity = set ? 0.8 : el.userData?.prewOpacity;
				if(set) {
					el.userData.prewColor = el.material.color.getHex();
					el.userData.prewOpacity = el.material.opacity;
				} else {
					delete el.userData.prewColor;
					delete el.userData.prewOpacity;
				}
				el.material.setValues({color: color, opacity: opacity})
			}
		})
		this.scene.render();
	}

	/**
	 * Method that creates processing based on specified type.
	 * @param {string} type - The type of processing to create (e.g., 'hole', 'rabbet', 'groove').
	 * @return {void}
	 */
	createProcessing(type = null) {
		if(empty(type)) return
		this.getDetailProcessing(this.processingTypes[type])
			.forEach(el => {
				let mesh;
				switch (el.subType) {
					case 'rabbet':
						const [height, width] = [el.heightCalc, el.widthCalc]
						const saw = new BoxGeometry(width, height, el.depth)
						mesh = new Mesh(saw, this.getMeshMaterial('red', 1));
						mesh.position.x = el.xCalc
						mesh.position.y = el.yCalc
						mesh.position.z = el.side === 'front' ? this.w / 2 - el.depth / 2 : -this.w / 2 + el.depth / 2
						switch (el.edgeSide) {
							case 'left':
								mesh.position.x = -this.l / 2 + width / 2
								break;
							case 'right':
								mesh.position.x = this.l / 2 - width / 2
								break
							case 'top':
								mesh.position.y = this.h / 2 - height / 2
								break
							case 'bottom':
								mesh.position.y = -this.h / 2 + height / 2
								break
						}
						break
					case 'groove':
						const [gheight, gwidth] = [el.heightCalc, el.widthCalc]
						const gsaw = new BoxGeometry(gwidth, gheight, el.depth)
						mesh = new Mesh(gsaw, this.getMeshMaterial('red', 1));
						mesh.position.x = el.xCalc - this.l / 2 + gwidth / 2
						mesh.position.y = el.yCalc - this.h / 2 + gheight / 2
						switch (el.side) {
							case 'left':
								mesh.position.x = -this.l / 2 + gwidth / 2
								mesh.position.z = el.z - this.w / 2 + gwidth / 2
								break;
							case 'right':
								mesh.position.x = this.l / 2 - gwidth / 2
								mesh.position.z = el.z - this.w / 2 + gwidth / 2
								break
							case 'top':
								mesh.position.y = this.h / 2 - gheight / 2
								mesh.position.z = el.z - this.w / 2 + gheight / 2
								break
							case 'bottom':
								mesh.position.y = -this.h / 2 + gheight / 2
								mesh.position.z = el.z - this.w / 2 + gheight / 2
								break
							case 'front':
								mesh.position.z = this.w / 2 - el.depth / 2
								break
							case 'back':
								mesh.position.z = -this.w / 2 + el.depth / 2
						}
						break
				}
				if(!empty(mesh)) {
					mesh.userData.isPocessing = true;
					mesh.userData.pId = el.id;
					mesh.userData.type = type
					mesh.userData.side = el.side
					this.mesh.add(mesh)
				}

		})
	}

	/**
	 * Method to retrieve details based on given types.
	 *
	 * @param {Array<string>} types - An array of strings indicating the types of details to retrieve.
	 *
	 * @return {Array} - Returns an array containing details based on the given types.
	 */
	getDetailProcessing(types) {
		const detailList = BaseClass.getProjectState('details');
		if(empty(detailList)) return [];
		const detail = detailList.find(el => el.detailId === this.detailId);
		if(empty(detail)) return [];
		let data = []
		types.forEach(type => {
			data = [...data, ...detail[type]]
		})
		return data
	}

	remove() {
		this.db.deleteDetail(this.detailCId)
			.then(() => ConnectClass.removeDetailConnections(this.detailCId))
			.then(() => {
				this.scene.removeObject(this.mesh);
				store.dispatch({
					type: 'REMOVE_DETAIL',
					payload: this
				})
			})

	}

	addHistoryState(type, from, to, changes) {
		const {name, detailCId} = this;
		let pos = '', rot = '', posC = 0, rotC = 0, description='';
		for(const [axis, value] of Object.entries(to)) {
			if(from[axis] !== value) {
				posC++
				pos += `${!empty(pos) ? ', ' : ''}${axis}: ${(value - from[axis]).toFixed(1)}мм`
			}
		}
		for(const [axis, value] of Object.entries(to)) {
			if(from[axis] !== value) {
				rotC++
				rot += `${!empty(rot) ? ', ' : ''}${axis}: ${((value - from[axis]) * (180 / Math.PI)).toFixed(1)}градусів`
			}
		}
		switch (type) {
			case 'rotation':
				description = `Поворот по ${rotC > 1 ? 'осям:' : 'осі'} ${rot}`
				break
			case 'position':
				description = `Зміщення по ${posC > 1 ? 'осям:' : 'осі'} ${pos}`
				break
			case 'created':
				description = 'Created';
				break
			default:
				description = `${from} ${to}`
		}
		this.history.addState({
			detail: {name, detailCId},
			description,
			changes
		})
	}

	restoreHistoryState({type, from}) {
		this[type] = from;
	}

	applyHistoryState({type, to}) {
		this[type] = to;
	}

	toggle() {
		this.hidden = !this.hidden;
		this.mesh.traverse( (node) => {
			if (node instanceof Mesh || node instanceof Line) {
				if(this.hidden) {
					node.userData.visible = node.material.visible;
				}
				node.material.visible = this.hidden ? false : node.userData.visible;
			}
		});
		this.scene.render()
	}

	selectObject(uuid) {
		const obj = this.mesh.children.find(el => el.uuid === uuid);
		if(!empty(obj)) {
			const isSelected = !obj.userData?.isSelected;
			if(isSelected) {
				obj.userData.color = '#' + obj.material.color.getHexString()
			}
			obj.userData.isSelected = isSelected
			obj.material.setValues({
				color: isSelected ? Helper.colorActive : obj.userData.color
			})
		}
		this.scene.render()
	}

	toggleSelectDetail(selected = false) {
		this.selected = selected;
		this.mesh.children.forEach(el => {
			if(el.userData?.meshType && el.userData?.meshType === 'layer') {
				el.material.setValues({
					color: selected ? Helper.colorActive : el.userData.color
				})
			}
		})
		this.scene.render();
	}

	detectDimensionAxis() {
		const detailBox = new Box3().setFromObject(this.mesh);
		const defMarker = this.mesh.children.find(el => el.userData?.isDefMarker);
		const defMarkerBox = new Box3().setFromObject(defMarker)
		const defMarkerSize = defMarkerBox.getSize(new Vector3())

		for(const [defAxis, defValue] of Object.entries(this.defMarkerAxis)) {
			for(const [axis, value] of Object.entries(defMarkerSize)) {
				if(Number(value.toFixed(1)) === defValue) {
					this.axisDefault[axis] = defAxis;
					switch (defAxis) {
						case 'x':
							this.dimension[axis] = 'l';
							break;
						case 'y':
							this.dimension[axis] = 'h';
							break;
						case 'z':
							this.dimension[axis] = 'w';
							break;
					}
				}
			}
		}
		const {max: detailMax, min: detailMin} = detailBox;
		const {max: markerMax, min: markerMin} = defMarkerBox;
		for(const[dAxis, dValue] of Object.entries(detailMax)) {
			if(Number(dValue.toFixed(1)) === Number(markerMax[dAxis].toFixed(1))) {
				this.zeroValues[dAxis] = 'max';
			}
		}
		for(const[dAxis, dValue] of Object.entries(detailMin)) {
			if(Number(dValue.toFixed(1)) === Number(markerMin[dAxis].toFixed(1))) {
				this.zeroValues[dAxis] = 'min';
			}
		}
	}

	get position() {
		return this._position;
	}

	set position(pos) {
		this._position = {...this.position, ...pos}
		for(const [axis, val] of Object.entries(this.position)) {
			this.mesh.position[axis] = val
		}
		this.db.updateDetail(this.dbData, 'position')
			.then(() => this.scene.render())

  }

	get rotation() {
		return this._rotation;
	}

	set rotation(rotation) {
		this._rotation = {...this.rotation, ...rotation}
		this.mesh.setRotationFromEuler(new Euler(...Object.values(this.rotation)));
		this.db.updateDetail(this.dbData, 'rotation')
			.then(() => this.scene.render())
	}

	addError(errorText) {
		const id = uuidv4();
		this.errors[id] = errorText;
	}

	deleteError(id) {
		delete this.errors[id];
	}
	addToGroup(group) {
		this.group = group;
		Promise.all(this.connections.map(el => this.removeConnection(el.connectionId)))
			.then(() => this.db.updateDetail(this.dbData, 'group'))
			.then(() => {
				this.toggleSelectDetail(false);
				store.dispatch({type: 'ADD_DETAIL_GROUP', payload: {
						detailCId: this.detailCId,
						group
					}})
			}
			)
	}

	removeFromGroup() {
		this.group = null;
		this.db.updateDetail(this.dbData, 'group')
			.then(() => store.dispatch({type: 'REMOVE_DETAIL_GROUP', payload: this.detailCId})
			)
	}

	updatePosition(updateData = null) {
		this.mesh.updateMatrixWorld(true);
		let rotationChanged = false, positionChanged = false;
		const oldRotation = {...this.rotation};
		const oldPosition = {...this.position};
		const newPosition = new Vector3();
		this.mesh.getWorldPosition(newPosition);
		// const newPosition = {x: this.mesh.worldPosition.x, y: this.mesh.worldPosition.y, z: this.mesh.worldPosition.z};
		const newRotation = {x: this.mesh.rotation.x, y: this.mesh.rotation.y, z: this.mesh.rotation.z}
		for(const [axis, value] of Object.entries(newPosition)) {
			if(oldPosition[axis] !== value) {
				positionChanged = true
			}
		}
		for(const [axis, value] of Object.entries(newRotation)) {
			if(oldRotation[axis] !== value) {
				rotationChanged = true
			}
		}
		const changes = [];
		if(!empty(updateData)) {
			if(rotationChanged) {
				this.rotation = newRotation;
				changes.push({
					type: 'rotation',
					from: oldRotation,
					to: {...this.rotation}
				})
			}

			if(positionChanged) {
				this.position = newPosition;
				changes.push({
					type: 'position',
					from: oldPosition,
					to: {...this.position}
				})
			}

			this.addHistoryState(
				updateData.type,
				`${updateData.usedObject}`,
					`${updateData.toDetail}->${updateData.toObject}`,
				changes
				)
		} else {

			if(rotationChanged) {
				this.rotation = newRotation;
				this.addHistoryState('rotation', oldRotation, {...this.rotation}, {
					type: 'rotation',
					from: oldRotation,
					to: {...this.rotation}
				})
			}
			if(positionChanged) {
				this.position = newPosition;
				this.addHistoryState('position', oldPosition, {...this.position}, {
					type: 'position',
					from: oldPosition,
					to:{...this.position}
				})
			}
		}
		if(rotationChanged || positionChanged) this.scene.render();
	}

	updateSize({l = 0, h = 0}) {
		if(l > 0) {
			this.l = l;
		}
		if(h > 0) {
			this.h = h;
		}
		return this.updateProjectDetail()
	}

	updateSizeParams({l = 0, h = 0}) {
		if(l > 0) {
			this._l = l;
		}
		if(h > 0) {
			this._h = h;
		}
	}

	createBoxMesh() {
		const geometry = new BoxGeometry(this.l, this.h, this.w);
		geometry.center()
		const mesh = new Mesh(geometry, this.getMeshMaterial('#029', 0.6));
		mesh.position.z = -this.w / 2;
		mesh.position.x = -this.l / 2;
		mesh.position.y = -this.h / 2;

		for(const [axis, val] of Object.entries(this.position)) {
			mesh.position[axis] = val
		}
		mesh.setRotationFromEuler(new Euler(...Object.values(this.rotation)));

		return mesh;
	}

	get l() {
      return this._l;
  }

  set l(value) {
      this._l = value;
	    this.db.updateDetail(this.dbData, 'l')
  }

  get h() {
      return this._h;
  }

  set h(value) {
      this._h = value;
	    this.db.updateDetail(this.dbData, 'h')
  }

  get contour() {
      return this._contour;
  }

  set contour(value) {
      this._contour = value;
  }

	get w() {
		return this._w;
	}

	set w(w) {
		this._w = w;
	}

	set renderType(type) {
		this._renderType = Number(type);
	}

	get renderType() {
		return this._renderType
	}

	get groupId() {
		return this._groupId
	}
	set groupId(groupId) {
		this._groupId = groupId;
	}
	get processingShoved() {
		return BaseClass.getState('showProcessing');
	}

	get lockedProcessing() {
		return this._lockedProcessing
	}

	set lockedProcessing(l) {
		this._lockedProcessing = l;
	}
}