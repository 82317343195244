import {Suspense, Component} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import routerConfig from "config/router";
import MainPage from "pages/MainPage";
import Projects from "pages/Projects";
import Info from "pages/InfoPage";
import Details from "pages/Details";
import Loader from "components/UI/Loader";
import Processes from "pages/Processes";

import {projectOperations} from "redux/project";
import Construction from "models/Construction";
import {connect} from "react-redux";
import ImportProject from "../../pages/ImportProject";
import Support from "../../pages/Support";
import Cost from "../../pages/EstimatedCost";
import Constructor from "../../pages/Constructor"
import {TableRefContextProvider} from "../../contexts";

class AppRouter extends Component {

	constructor(props) {
		super(props);
		this.load = props.load || false;
		this.user = props.user ;
		this.page = props.page || null;
	}

	componentDidMount() {
		if (!this.props.load) {

			this.props.setConstruction();
		}
	}

	render() {
		const { user, load, page } = this.props;

		return (
			<BrowserRouter>
				<Suspense fallback={<Loader/>}>
					<TableRefContextProvider>
					<Routes>
						{user
							? <Route
								path={routerConfig.route.DEFAULT}
								element={<MainPage page={page} loaded={load}/>}
								exact={true}
							/>
							: <Route
								path={routerConfig.route.DEFAULT}
								element={<Info/>}
								exact={true}
							/>
						}
						<Route
							path={routerConfig.route.PROJECTS}
							element={<Projects/>}
							exact={true}
						/>
						<Route
							path={routerConfig.route.INFO}
							element={<Info/>}
							exact={true}
						/>
						<Route
							path={routerConfig.route.DETAILS}
							element={<Details/>}
							exact={true}
						/>
						<Route
							path={routerConfig.route.PROCESSES}
							element={<Processes/>}
							exact={true}
						/>
						<Route
						path={routerConfig.route.IMPORT}
						element={<ImportProject/>}
						exact={true}
					/>
						<Route
						path={routerConfig.route.SUPPORT}
						element={<Support />}
						exact={true}
					/>
						<Route
							path={routerConfig.route.COST}
							element={<Cost />}
							exact={true}
						/>
						<Route
							path={routerConfig.route.CONSTRUCTOR}
							element={<Constructor />}
							exact={true}
						/>
						{/* <Route
              path={routerConfig.route.CALCULATE}
              element={<Calculate />}
              exact={true}
            /> */}
					</Routes>
					</TableRefContextProvider>
				</Suspense>
			</BrowserRouter>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setConstruction: () => {
			dispatch(
				projectOperations.setConstruction(new Construction({}, dispatch))
			);
		},
	};
};
export default connect(null, mapDispatchToProps)(AppRouter);
// export default AppRouter;
