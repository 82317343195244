import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import { constructor3dSelectors, constructor3dOperations } from "../../../../redux/constructor";
import {empty} from "../../../../helpers/helper";
import Panel from "../../layuot/panel";
import ConnectDetails from "../../../model/components/ConnectDetails";
import Helper from "../../../model/3d/Helper";
import ConnectDetailsOptions from "./../ConnectDetailsOptions";

const SelectedObjects = (details) => {
	const selectedObjects = useSelector(constructor3dSelectors.getSelectedObjects);
	const [mainObject, setMainObject] = useState(selectedObjects[0] || null)
	const connectionValues = useSelector(constructor3dSelectors.getConnectValues)
	const connect = new ConnectDetails(selectedObjects);
	const dispatch = useDispatch();
	const [buttonText, setButtonText] = useState('редагувати')


	// useEffect(() => {
	// 	if(!empty(mainObject)) {
	// 		connect.mainObject = mainObject;
	// 	}
	//
	// 	// console.log(connect.mainObject)
	// }, [mainObject]);

	useEffect(() => {
		if(!empty(selectedObjects)) {
			setMainObject(selectedObjects[0].object)
			// connect.objects = selectedObjects.map(o => o.object);
		}
	}, [selectedObjects]);


	useEffect(() => {
		setButtonText(!empty(connectionValues.connectionId) ? 'редагувати' : 'приєднати');
	}, [connectionValues]);

	if(empty(selectedObjects)) return null;



	const handleMainObject = (e, i) => {
		e.preventDefault()
		setMainObject(selectedObjects[i])
	}

	const handleOpenOptions = () => {
		dispatch(constructor3dOperations.toggleConnectModal())
	}

	const handleCoincidentObjects = offset => {
		// connect.mainObject = mainObject;
		const _offset = {};
		for(const [prop, value] of Object.entries(offset)) {
			if(prop !== 'connectionId') {
				_offset[prop] = value;
			}
		}
		connect.connectObjects({
			mainObject,
			targetObject: selectedObjects.find(el => el.object.uuid !== mainObject.uuid)?.object,
			offset: _offset,
			type:'coincident',
			connectionId: offset.connectionId
		})
		dispatch(constructor3dOperations.setConnectionValues(_offset))
			.then(() => dispatch(constructor3dOperations.setConnectionOptions(false)))

	}
	const handleParallelObjects = e => {
		e.preventDefault();
		connect.mainObject = mainObject;
		connect.connectObjects('parallel')
		// console.log(connect.mainObject)
	}

	const deleteSelectedObject = (obj) => {
		obj.material.setValues({
			color: obj.userData.color
		})
		obj.userData.isSelected = false
		dispatch(constructor3dOperations.removeSelectedObject(obj.uuid))
			.then(() => {
				const _offset = {};
				for(const key in connectionValues) {
					_offset[key] = '';
				}
				return dispatch(constructor3dOperations.setConnectionValues(_offset))
			})
	}

	const getConnectButton = () => {
		if (selectedObjects.length !== 2) return null;

		return <button onClick={handleOpenOptions}>{buttonText}</button>
	}

	// console.log(selectedObjects, mainObject)

	return <Panel className="p__content" title="Вибрані objects">
		{selectedObjects.map((el, i) => {
			return <div className="flex-justify-between d-flex">
				{el.object.uuid === mainObject?.uuid ?
					<strong>{el.object.userData.name}</strong> :
					<a onClick={e=> handleMainObject(e, i)}>{el.object.userData.name}</a>
				}
				<a className="remove-button" onClick={e => deleteSelectedObject(el.object)}> &times;</a>
			</div>
		})}
		<div>
			{getConnectButton()}
		</div>

	</Panel>
}
export default SelectedObjects